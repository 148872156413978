// src/components/Dashboard.js
import React, { useEffect, useRef, useCallback } from 'react';
import { useTheme } from '../ThemeContext';
import ThemeSwitch from './ThemeSwitch';
import { useAuth } from '../contexts/AuthContext';
import EntrySummary from './Summary/EntrySummary';
import ProjectionPanel from './ProjectionPanel';
import ContributionGraph from './ContributionGraph'; // <-- Nuevo Componente

const Dashboard = () => {
  const { theme, isSidebarVisible, toggleSidebar } = useTheme();
  const { user } = useAuth();
  const dashboardRef = useRef(null);

  const handleUserInteraction = useCallback(() => {
    if (isSidebarVisible) {
      toggleSidebar();
    }
  }, [isSidebarVisible, toggleSidebar]);

  useEffect(() => {
    const dashboardCurrent = dashboardRef.current;
    if (dashboardCurrent) {
      dashboardCurrent.addEventListener('click', handleUserInteraction);
      dashboardCurrent.addEventListener('scroll', handleUserInteraction);
    }

    return () => {
      if (dashboardCurrent) {
        dashboardCurrent.removeEventListener('click', handleUserInteraction);
        dashboardCurrent.removeEventListener('scroll', handleUserInteraction);
      }
    };
  }, [handleUserInteraction]);

  // Datos de ejemplo para el gráfico de contribuciones
  const contributionData = Array.from({ length: 51 }, (_, weekIndex) => ({
    week: weekIndex,
    days: Array.from({ length: 7 }, (_, dayIndex) => ({
      date: new Date(2024, 0, weekIndex * 7 + dayIndex + 1),
      count: Math.floor(Math.random() * 5),
      color: `bg-green-${Math.floor(Math.random() * 4 + 3)}00`
    }))
  }));

  return (
    <div
      ref={dashboardRef}
      className={`flex flex-col flex-grow transition-colors duration-300 font-roboto 
        ${theme === 'dark' ? 'bg-gray-900 text-gray-100' : 'bg-gray-100 text-gray-900'} 
        ${isSidebarVisible ? '' : 'w-full'}`}
    >
      {/* Interruptor de Tema */}
      <div className="flex justify-end p-4">
        <ThemeSwitch />
      </div>

      {/* Mensaje de Bienvenida */}
      <div className="text-center mb-4">
        <h1 className="text-xl font-semibold">
          Bienvenido{user ? `, ${user.displayName}` : ''}!
        </h1>
        <p className="text-gray-700 dark:text-gray-300 text-sm">
          {user
            ? 'Explora tus métricas de salud y actividades.'
            : 'Regístrate o inicia sesión para acceder a todas las funcionalidades.'}
        </p>
      </div>

      {/* Contenido principal */}
      <div className="flex-grow p-4 overflow-y-auto flex flex-col gap-4">
        {/* Resumen en la parte superior */}
        <div className="w-full">
          <EntrySummary />
        </div>

        {/* Proyección */}
        <div className="w-full">
          <ProjectionPanel />
        </div>
        
        {/* Gráfico de Contribuciones */}
        <div className="w-full">

          <ContributionGraph data={contributionData} />
        </div>
        
      </div>
    </div>
  );
};  

export default Dashboard;
