// src/components/Sidebar.js
import React, { useEffect } from 'react';
import { useTheme } from '../ThemeContext';
import HealthMetricsForm from './formats/HealthMetricsForm';
import Auth from './Auth';
import { ReactComponent as ToggleSide } from './toggleside.svg';
import { useAuth } from '../contexts/AuthContext';

const Sidebar = () => {
  const { theme, isSidebarVisible, toggleSidebar } = useTheme();
  const { user } = useAuth();

  useEffect(() => {
    if (isSidebarVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isSidebarVisible]);

  return (
    <>
      {/* Toggle button with user photo */}
      <button
        className={`${
          theme === 'dark' ? 'bg-gray-700' : 'bg-gray-200'
        } 
          p-0.5 
          rounded-full 
          fixed 
          top-2 
          left-2 
          shadow-md 
          hover:bg-opacity-80 
          transition-all 
          z-50 
          flex 
          items-center 
          justify-center
          w-8 
          h-8`}
        onClick={toggleSidebar}
        aria-label="Toggle Sidebar"
      >
        {user ? (
          <div className="relative">
            <img
              src={user.photo}
              alt={`${user.displayName}'s profile`}
              className="w-8 h-8 rounded-full object-cover border-2 border-blue-400 hover:border-blue-300 transition-all"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/default-avatar.png';
              }}
            />
          </div>
        ) : (
          <ToggleSide
            className={`${
              isSidebarVisible ? 'transform rotate-180' : ''
            } text-blue-400`}
          />
        )}
      </button>

      {/* Sidebar Content */}
      <div
        className={`scrollbar scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-transparent transform ${
          isSidebarVisible ? 'translate-x-0' : '-translate-x-full'
        } ${
          theme === 'dark' ? 'bg-black text-white' : 'bg-gray-100 text-gray-900'
        } 
          fixed 
          top-0 
          left-0 
          w-64 
          h-full 
          z-40 
          shadow-2xl 
          transition-transform 
          duration-300 
          ease-in-out 
          overflow-y-auto`}
      >
        {/* Removed logo */}
        
        <Auth />

        <div className="my-4 border-t border-gray-300"></div>
        {/* Removed title */}
        <HealthMetricsForm />
      </div>
    </>
  );
};

export default Sidebar;