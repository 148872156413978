import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useAuth } from './AuthContext';
import {
  getHealthMetrics as apiGetHealthMetrics,
  saveHealthMetrics as apiSaveHealthMetrics,
  deleteHealthMetrics as apiDeleteHealthMetrics,
  getPlans as apiGetPlans,
  savePlan as apiSavePlan,
  updatePlan as apiUpdatePlan,
  deletePlan as apiDeletePlan,
  getEntries,
  saveEntry as apiSaveEntry,
  deleteEntry as apiDeleteEntry,
} from '../Sidebar/formats/apiService';
import { startOfDay, parseISO, isValid } from 'date-fns';

// ---------------------------------------------------------
// 1. Creamos el contexto
// ---------------------------------------------------------
const PlanContext = createContext();

/**
 * Función para parsear una cadena de fecha 'YYYY-MM-DD' o 'YYYY-MM-DDTHH:MM:SSZ' como una fecha local.
 * @param {string} dateString - Cadena de fecha en formato ISO.
 * @returns {Date|null} - Objeto Date con la hora local o null si inválida.
 */
const parseLocalDate = (dateString) => {
  if (!dateString) return null;
  const parsedDate = parseISO(dateString);
  return isValid(parsedDate) ? parsedDate : null;
};

// ---------------------------------------------------------
// 2. Proveedor del contexto
// ---------------------------------------------------------
export const PlanProvider = ({ children }) => {
  const { user, loading: authLoading } = useAuth();

  // =======================
  // Gestión de Fecha Actual
  // =======================
  const todayDate = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  // =======================
  // Estado y Funciones del Contexto
  // =======================
  const [selectedDate, setSelectedDate] = useState(todayDate);

  // Preferencias del usuario
  const [caloricIntake, setCaloricIntake] = useState(
    localStorage.getItem('caloricIntake') || 'recomp'
  );
  const [useCreatine, setUseCreatine] = useState(
    localStorage.getItem('useCreatine') === 'true' || false
  );

  // Entradas y plan
  const [entries, setEntries] = useState([]);
  const [planData, setPlanData] = useState(null);
  const [planCreated, setPlanCreated] = useState(false);
  const [plans, setPlans] = useState([]);

  // Métricas y errores
  const [healthMetrics, setHealthMetrics] = useState(null);
  const [error, setError] = useState(null);

  // Control de carga
  const [isLoading, setIsLoading] = useState(true);

  // Derivar planId de planData
  const planId = useMemo(() => (planData ? planData._id : null), [planData]);

  // Guardar preferencias localmente
  useEffect(() => {
    localStorage.setItem('caloricIntake', caloricIntake);
  }, [caloricIntake]);

  useEffect(() => {
    localStorage.setItem('useCreatine', useCreatine);
  }, [useCreatine]);

  // ---------------------------------------------------------
  // 3. Efecto para obtener métricas de salud
  // ---------------------------------------------------------
  useEffect(() => {
    const fetchHealthMetrics = async () => {
      if (user) {
        try {
          const metrics = await apiGetHealthMetrics();
          setHealthMetrics(metrics);
          localStorage.setItem('healthMetrics', JSON.stringify(metrics));
        } catch (err) {
          console.error('Error al obtener las métricas de salud:', err);
          setError(err.message);
        }
      } else {
        // Si no hay user, cargar de localStorage
        const storedMetrics = localStorage.getItem('healthMetrics');
        const localMetrics = storedMetrics ? JSON.parse(storedMetrics) : null;
        setHealthMetrics(localMetrics);
      }
    };
    fetchHealthMetrics();
  }, [user]);

  // ---------------------------------------------------------
  // 4. Efecto para obtener y configurar los planes
  // ---------------------------------------------------------
  useEffect(() => {
    const fetchPlans = async () => {
      if (user) {
        try {
          const plansData = await apiGetPlans();
          console.log('Planes obtenidos de la API:', plansData);
          setPlans(plansData);

          // Limpiar localStorage
          localStorage.removeItem('planData');
          localStorage.removeItem('entries');

          if (plansData && plansData.length > 0) {
            // Tomar el primer plan
            const rawPlan = plansData[0];
            console.log('Datos del plan recibido:', rawPlan);

            const parsedStart = parseLocalDate(rawPlan.start);
            const parsedEnd = parseLocalDate(rawPlan.end);

            if (parsedStart && parsedEnd) {
              const plan = {
                ...rawPlan,
                start: startOfDay(parsedStart),
                end: startOfDay(parsedEnd),
              };
              localStorage.setItem('planData', JSON.stringify(plan));
              setPlanData(plan);
              setPlanCreated(true);

              // Cargar entradas asociadas
              try {
                const fetchedEntries = await getEntries(
                  plan._id,
                  plan.start,
                  plan.end
                );
                setEntries(fetchedEntries);
              } catch (entryErr) {
                console.error('Error al obtener las entradas:', entryErr);
                setError(entryErr.message);
              }
            }
          } else {
            // No hay planes en el servidor
            // Revisar si hay uno local
            const storedPlanData = localStorage.getItem('planData');
            const localPlanData = storedPlanData
              ? JSON.parse(storedPlanData)
              : null;

            if (localPlanData) {
              // Usar plan local
              setPlanData(localPlanData);
              setPlanCreated(true);
              try {
                const fetchedEntries = await getEntries(
                  localPlanData._id,
                  localPlanData.start,
                  localPlanData.end
                );
                setEntries(fetchedEntries);
              } catch (entryErr) {
                console.error('Error al obtener entradas local:', entryErr);
                setError(entryErr.message);
              }
            } else {
              // No hay planes
              setPlanData(null);
              setPlanCreated(false);
              setEntries([]);
            }
          }
        } catch (err) {
          console.error('Error al obtener los planes:', err);
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      } else {
        // Usuario no logueado: tratar de usar plan local
        const storedPlanData = localStorage.getItem('planData');
        const localPlanData = storedPlanData
          ? JSON.parse(storedPlanData)
          : null;

        if (localPlanData) {
          const parsedStart = parseLocalDate(localPlanData.start);
          const parsedEnd = parseLocalDate(localPlanData.end);

          if (parsedStart && parsedEnd) {
            const localPlan = {
              ...localPlanData,
              start: startOfDay(parsedStart),
              end: startOfDay(parsedEnd),
            };
            setPlanData(localPlan);
            setPlanCreated(true);

            try {
              const fetchedEntries = await getEntries(
                localPlan._id,
                localPlan.start,
                localPlan.end
              );
              setEntries(fetchedEntries);
            } catch (entryErr) {
              console.error('Error al obtener entradas local:', entryErr);
              setError(entryErr.message);
            }
          }
        }
        setIsLoading(false);
      }
    };
    fetchPlans();
  }, [user]);

  // ---------------------------------------------------------
  // 5. Manejo de creación y eliminación de planes
  // ---------------------------------------------------------
  const handleCreatePlan = useCallback(async () => {
    if (
      !healthMetrics ||
      !healthMetrics.weight ||
      !healthMetrics.height ||
      !healthMetrics.age ||
      !healthMetrics.sex ||
      !healthMetrics.activityFactor
    ) {
      setError(
        'Las métricas de salud no están completas. Por favor, completa tus datos de salud.'
      );
      return;
    }

    if (!planData) {
      // Valor por defecto
      const fitnessLevel = 'intermedio';

      const newPlan = {
        fitnessLevel,
        caloricIntake,
        useCreatine,
      };

      try {
        let savedPlan;
        if (user) {
          savedPlan = await apiSavePlan(newPlan);
          if (!savedPlan) {
            throw new Error('No se pudo guardar el plan en el servidor.');
          }
          // Limpiar localStorage
          localStorage.removeItem('planData');
          localStorage.removeItem('entries');
          localStorage.setItem('planData', JSON.stringify(savedPlan));
        } else {
          // Offline: guardar en localStorage
          savedPlan = newPlan;
          localStorage.setItem('planData', JSON.stringify(savedPlan));
        }

        setPlanData(savedPlan);
        setPlanCreated(true);
        setEntries([]); // Limpiamos entradas

      } catch (err) {
        console.error('Error al crear el plan:', err);
        setError('No se pudo guardar el plan. Por favor, intenta nuevamente.');
      }
    }
  }, [planData, caloricIntake, useCreatine, user, healthMetrics]);

  const handleDeletePlan = useCallback(async () => {
    if (planData && planData._id) {
      try {
        if (user) {
          await apiDeletePlan(planData._id);
        }
        // Resetear estado y localStorage
        setPlanData(null);
        setPlanCreated(false);
        setEntries([]);
        localStorage.removeItem('planData');
        localStorage.removeItem('entries');
        setError(null);
      } catch (err) {
        console.error('Error al eliminar el plan:', err);
        setError('Hubo un error al eliminar el plan. Intenta nuevamente.');
      }
    }
  }, [planData, user]);

  // ---------------------------------------------------------
  // 6. Manejo de entradas
  // ---------------------------------------------------------
  const addEntry = useCallback(
    async (entryData) => {
      if (!planData?._id) {
        setError('No hay un plan seleccionado. No se puede agregar la entrada.');
        return { success: false, message: 'No hay un plan seleccionado.' };
      }
      try {
        const savedEntry = await apiSaveEntry({
          ...entryData,
          planId: planData._id,
        });
        // Actualizar state
        setEntries((prevEntries) => [...prevEntries, savedEntry]);
        setError(null);
        return { success: true };
      } catch (err) {
        console.error('Error al agregar la entrada:', err);
        setError('Error al agregar la entrada. Intenta nuevamente.');
        return {
          success: false,
          message: 'Error al agregar la entrada. Intenta nuevamente.',
        };
      }
    },
    [planData]
  );

  const deleteEntryFn = useCallback(async (entryId) => {
    try {
      const result = await apiDeleteEntry(entryId);
      if (result.success) {
        setEntries((prevEntries) =>
          prevEntries.filter((entry) => entry._id !== entryId)
        );
        setError(null);
        return { success: true };
      } else {
        setError(result.message || 'Error al eliminar la entrada.');
        return { success: false, message: result.message };
      }
    } catch (err) {
      console.error('Error al eliminar la entrada:', err);
      setError('Error al eliminar la entrada. Intenta nuevamente.');
      return {
        success: false,
        message: 'Error al eliminar la entrada. Intenta nuevamente.',
      };
    }
  }, []);

  // ---------------------------------------------------------
  // 7. Funciones expuestas para Manejo de Datos (API HealthMetrics, Plans)
  // ---------------------------------------------------------
  const getHealthMetricsFn = useCallback(async () => {
    try {
      const metrics = await apiGetHealthMetrics();
      setHealthMetrics(metrics);
      localStorage.setItem('healthMetrics', JSON.stringify(metrics));
      return metrics;
    } catch (err) {
      console.error('Error en getHealthMetrics:', err);
      throw err;
    }
  }, []);

  const saveHealthMetricsData = useCallback(async (data) => {
    try {
      const savedMetrics = await apiSaveHealthMetrics(data);
      setHealthMetrics(savedMetrics);
      localStorage.setItem('healthMetrics', JSON.stringify(savedMetrics));
      return savedMetrics;
    } catch (err) {
      console.error('Error en saveHealthMetrics:', err);
      throw err;
    }
  }, []);

  const deleteHealthMetricsData = useCallback(async () => {
    try {
      await apiDeleteHealthMetrics();
      setHealthMetrics(null);
      localStorage.removeItem('healthMetrics');
    } catch (err) {
      console.error('Error en deleteHealthMetrics:', err);
      throw err;
    }
  }, []);

  const getPlansData = useCallback(async () => {
    try {
      const plansData = await apiGetPlans();
      setPlans(plansData);
      return plansData;
    } catch (err) {
      console.error('Error en getPlans:', err);
      throw err;
    }
  }, []);

  const savePlanDataFn = useCallback(async (newPlanData) => {
    try {
      const savedPlan = await apiSavePlan(newPlanData);
      setPlans((prevPlans) => [...prevPlans, savedPlan]);
      localStorage.setItem('planData', JSON.stringify(savedPlan));
      return savedPlan;
    } catch (err) {
      console.error('Error en savePlan:', err);
      throw err;
    }
  }, []);

  // **AQUÍ ESTÁ LA MODIFICACIÓN IMPORTANTE**:
  const updatePlanData = useCallback(async (planId, updatedData) => {
    try {
      const updatedPlan = await apiUpdatePlan(planId, updatedData);

      // Actualizamos la lista de planes...
      setPlans((prevPlans) =>
        prevPlans.map((plan) => (plan._id === planId ? updatedPlan : plan))
      );

      // Y **también** actualizamos planData para que se reflejen los cambios
      setPlanData(updatedPlan);

      localStorage.setItem('planData', JSON.stringify(updatedPlan));
      return updatedPlan;
    } catch (err) {
      console.error('Error en updatePlan:', err);
      throw err;
    }
  }, []);

  const deletePlanData = useCallback(async (planId) => {
    try {
      await apiDeletePlan(planId);
      setPlans((prevPlans) => prevPlans.filter((plan) => plan._id !== planId));
      localStorage.removeItem('planData');
    } catch (err) {
      console.error('Error en deletePlan:', err);
      throw err;
    }
  }, []);

  // ---------------------------------------------------------
  // 8. Funciones para el calendario (opcionales)
  // ---------------------------------------------------------
  const getEntriesForDate = useCallback(
    (date) => {
      const dateStr = date.toISOString().split('T')[0];
      return entries.filter(
        (entry) => entry.date && entry.date.startsWith(dateStr)
      );
    },
    [entries]
  );

  const tileContent = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const entriesForDate = getEntriesForDate(date);
        return (
          <>
            {entriesForDate.map((entry) => (
              <div key={entry._id} className="entry-marker">
                {entry.summary}
              </div>
            ))}
          </>
        );
      }
      return null;
    },
    [getEntriesForDate]
  );

  const tileClassName = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const dateStr = date.toISOString().split('T')[0];
        const todayStr = todayDate.toISOString().split('T')[0];
        const selectedDateStr = selectedDate.toISOString().split('T')[0];

        let classes = '';
        if (dateStr === todayStr) classes += 'react-calendar__tile--now ';
        if (dateStr === selectedDateStr) classes += 'react-calendar__tile--active ';
        if (date < todayDate) classes += 'opacity-50 ';

        return classes.trim();
      }
      return null;
    },
    [todayDate, selectedDate]
  );

  const tileDisabled = useCallback(
    ({ date, view }) => {
      if (view === 'month') {
        const isPast = date < todayDate;
        const entriesForDate = getEntriesForDate(date);
        const isPartOfPlan = entriesForDate.length > 0;
        return isPast && !isPartOfPlan;
      }
      return false;
    },
    [getEntriesForDate, todayDate]
  );

  const isPlanActive = entries.length > 0;

  // ---------------------------------------------------------
  // 9. Efecto para actualizar fechas cuando se crea nuevo plan
  // ---------------------------------------------------------
  useEffect(() => {
    if (planCreated && planData) {
      if (planData.start && planData.end) {
        let { start, end } = planData;

        // Parsear si son strings
        if (typeof start === 'string') {
          start = parseLocalDate(start);
        }
        if (typeof end === 'string') {
          end = parseLocalDate(end);
        }

        // Verificar que start y end sean Date válidas
        if (
          start instanceof Date &&
          !isNaN(start) &&
          end instanceof Date &&
          !isNaN(end)
        ) {
          // Ajustar la fecha seleccionada al inicio del plan
          setSelectedDate(start);
        } else {
          console.warn('PlanData contiene fechas inválidas:', planData);
        }
      } else {
        console.warn('planData no contiene start o end:', planData);
      }
    }
  }, [planCreated, planData]);

  // ---------------------------------------------------------
  // 10. Agregar console.log para inspeccionar las entradas
  // ---------------------------------------------------------
  useEffect(() => {
    console.log('Estructura actual de las entradas:', entries);
  }, [entries]);

  // ---------------------------------------------------------
  // 11. Retorno del Provider
  // ---------------------------------------------------------
  return (
    <PlanContext.Provider
      value={{
        // Estados
        caloricIntake,
        setCaloricIntake,
        useCreatine,
        setUseCreatine,
        planData,
        setPlanData,
        planCreated,
        setPlanCreated,
        entries,
        setEntries,
        error,
        setError,
        plans,
        isLoading: isLoading || authLoading,
        healthMetrics,
        setHealthMetrics,

        // Funciones de manejo de plan
        handleDeletePlan,
        handleCreatePlan,

        // Funciones de entradas
        addEntry,
        deleteEntry: deleteEntryFn,

        // API
        getHealthMetrics: getHealthMetricsFn,
        saveHealthMetrics: saveHealthMetricsData,
        deleteHealthMetrics: deleteHealthMetricsData,
        getPlans: getPlansData,
        savePlan: savePlanDataFn,
        updatePlan: updatePlanData,      // <-- se llama a la función con el fix
        deletePlan: deletePlanData,

        // Calendario
        selectedDate,
        setSelectedDate,
        getEntriesForDate,
        tileContent,
        tileClassName,
        tileDisabled,
        isPlanActive,

        // planId derivado
        planId,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
};

PlanProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// ---------------------------------------------------------
// 12. Custom Hook
// ---------------------------------------------------------
export const usePlan = () => {
  const context = useContext(PlanContext);
  if (!context) {
    throw new Error('usePlan must be used within a PlanProvider');
  }
  return context;
};
