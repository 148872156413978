// EntryDetailModal.js
import React, { useEffect } from 'react';
import { MdClose } from 'react-icons/md';

const EntryDetailModal = ({ entry, onClose, theme }) => {
  // Cerrar con ESC
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  if (!entry) return null;

  const renderData = () => {
    switch(entry.type) {
      case 'food':
        const nutrition = entry.data?.nutritionDetails || {};
        return (
          <div className="space-y-2">
            <p><strong>Alimento:</strong> {entry.data.food}</p>
            <p><strong>Calorías:</strong> {nutrition.calories} kcal</p>
            <p><strong>Proteínas:</strong> {nutrition.protein} g</p>
            <p><strong>Carbohidratos:</strong> {nutrition.carbohydrates} g</p>
            <p><strong>Grasas:</strong> {nutrition.fat} g</p>
            <p><strong>Sodio:</strong> {nutrition.sodium} mg</p>
          </div>
        );
      case 'workout':
        return (
          <div className="space-y-2">
            <p><strong>Tipo de Ejercicio:</strong> {entry.data.workoutType}</p>
            <p><strong>Duración:</strong> {entry.data.duration} minutos</p>
            <p><strong>Calorías Quemadas:</strong> {entry.data.caloriesBurned} kcal</p>
          </div>
        );
      case 'mood':
        return (
          <div className="space-y-2">
            <p><strong>Estado de Ánimo:</strong> {entry.data.mood}</p>
          </div>
        );
      case 'state':
        return (
          <div className="space-y-2">
            <p><strong>Estado Físico:</strong> {entry.data.state}</p>
          </div>
        );
      default:
        return <p>No hay detalles disponibles para esta entrada.</p>;
    }
  };

  return (
    <div
      className="
        fixed inset-0 z-50 flex items-center justify-center
        bg-black bg-opacity-50
        transition-opacity duration-300
      "
      style={{ animation: 'fadeIn 0.3s' }} // Efecto de entrada
    >
      <div
        className={`
          ${
            theme === 'dark'
              ? 'bg-gray-800 text-gray-200'
              : 'bg-white text-gray-800'
          }
          rounded-lg shadow-lg w-11/12 max-w-md p-6 relative
          transition-transform duration-300
          transform scale-100
          animate-slideUp
        `}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
      >
        <button
          onClick={onClose}
          className={`
            absolute top-3 right-3
            ${
              theme === 'dark'
                ? 'text-gray-300 hover:text-gray-100'
                : 'text-gray-500 hover:text-gray-700'
            }
            transition-colors duration-300
          `}
          aria-label="Cerrar modal"
        >
          <MdClose className="w-6 h-6" />
        </button>
        <h2 
          id="modal-title" 
          className="text-xl font-semibold mb-4 capitalize"
        >
          Detalles de {entry.type}
        </h2>
        <div className="mb-4">
          <p><strong>ID:</strong> {entry.id || entry._id}</p>
          <p><strong>Fecha y Hora:</strong> {entry.dateTime}</p>
        </div>
        {renderData()}
        <button
          onClick={onClose}
          className={`
            mt-6 px-4 py-2 rounded-md font-semibold
            ${
              theme === 'dark'
                ? 'bg-indigo-600 hover:bg-indigo-500 text-white'
                : 'bg-indigo-500 hover:bg-indigo-600 text-white'
            }
            transition-all duration-300 transform hover:scale-105
          `}
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default EntryDetailModal;
