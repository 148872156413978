// src/context/AuthContext.js
import React, { createContext, useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// Create authentication context
const AuthContext = createContext();

// Custom hook to use AuthContext
export const useAuth = () => useContext(AuthContext);

// Base URL for authentication endpoints
const BASE_URL = 'https://api.carendary.com';

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();

  // Query to fetch user data
  const { data: user, isLoading, error } = useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/user`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            return null; // Not authenticated
          }
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.user;
      } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
      }
    },
    retry: false,
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep data in cache for 30 minutes
  });

  // Login function
  const login = () => {
    console.log('Initiating Google login');
    window.location.href = `${BASE_URL}/auth/google`;
  };

  // Logout function
  const logout = async () => {
    console.log('Logging out');
    try {
      // Invalidate user query before redirecting
      await queryClient.invalidateQueries(['user']);
      window.location.href = `${BASE_URL}/logout`;
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  // Provide auth context value
  const value = {
    user,
    loading: isLoading,
    error,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
