import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from '../ThemeContext';
import ChatWindow from './ChatWindow';
import { AnimatePresence, motion, useMotionValue } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { useAuth } from '../contexts/AuthContext';
import { useChat } from '../contexts/ChatContext';
import { usePlan } from '../contexts/PlanContext'; // Import usePlan from PlanContext

const doesChatNameExist = (name, chats) => {
  return chats.some((chat) => chat.name === name);
};

const AssistantChat = ({ isOpen, toggleChat }) => {
  const { theme } = useTheme();
  const { user, loading, login } = useAuth();
  const {
    chats,
    addChat,
    updateChat,
    deleteChat,
    currentChatId,
    setCurrentChatId,
    isLoading,
    sendMessage,
  } = useChat();

  const { planData } = usePlan(); // Access planData from PlanContext

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [input, setInput] = useState('');
  const [showLogin, setShowLogin] = useState(true); // State to control login window visibility
  const sidebarRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const buttonRef = useRef(null);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setIsSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    // Reset login window visibility when chat opens and user is not authenticated
    if (isOpen && !user) {
      setShowLogin(true);
    }
  }, [isOpen, user]);

  const generateUniqueChatName = () => {
    const baseName = 'Nueva Conversación';
    let newName = baseName;
    let counter = 1;

    while (doesChatNameExist(newName, chats)) {
      counter += 1;
      newName = `${baseName} ${counter}`;
    }

    return newName;
  };

  const handleNewChat = () => {
    const newName = generateUniqueChatName();
    const newChat = {
      id: uuidv4(),
      name: newName,
      messages: [],
    };
    addChat(newChat);
    setCurrentChatId(newChat.id);
    setIsSidebarOpen(true);
  };

  const handleSelectChat = (id) => {
    setCurrentChatId(id);
    setIsSidebarOpen(false);
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleRenameChat = (id, newName) => {
    if (!newName || newName.trim() === '') {
      alert('El nombre del chat no puede estar vacío.');
      return;
    }

    if (chats.some((chat) => chat.name === newName && chat.id !== id)) {
      alert('Ya existe un chat con ese nombre. Por favor, elige otro nombre.');
      return;
    }

    const updatedChat = chats.find((chat) => chat.id === id);
    if (updatedChat) {
      updateChat(id, { ...updatedChat, name: newName.trim() });
    }
  };

  const handleDeleteChat = (id) => {
    deleteChat(id);
    if (id === currentChatId) {
      const remainingChats = chats.filter((chat) => chat.id !== id);
      if (remainingChats.length > 0) {
        setCurrentChatId(remainingChats[0].id);
      } else {
        setCurrentChatId(null);
      }
    }
  };

  const currentChat = chats.find((chat) => chat.id === currentChatId);

  const handleSend = () => {
    if (input.trim() === '') return;
    sendMessage(input);
    setInput('');
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (isSidebarOpen) {
        setIsSidebarOpen(false);
      }
    },
    onSwipedRight: () => {
      if (!isSidebarOpen) {
        setIsSidebarOpen(true);
      }
    },
    delta: 50,
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  if (loading) {
    return null;
  }

  return (
    <div>
      <motion.button
        ref={buttonRef}
        onClick={toggleChat}
        className="fixed bottom-4 right-4 w-32 h-40 md:w-40 md:h-48 lg:w-48 lg:h-56 rounded-lg hover:bg-black hover:bg-opacity-10 flex items-center justify-center focus:outline-none z-40 transition-colors duration-200"
        aria-label={isOpen ? 'Cerrar chat' : 'Abrir chat'}
        drag
        dragMomentum={false}
        dragElastic={0}
        style={{ x, y }}
        onDragEnd={() => {
          const buttonRect = buttonRef.current.getBoundingClientRect();
          const buttonWidth = buttonRect.width;
          const buttonHeight = buttonRect.height;
          const screenWidth = window.innerWidth;
          const screenHeight = window.innerHeight;

          const initialLeft = screenWidth - buttonWidth - 16;
          const initialTop = screenHeight - buttonHeight - 16;

          const currentX = x.get();
          const currentY = y.get();

          const buttonLeft = initialLeft + currentX;
          const buttonTop = initialTop + currentY;

          const distanceToLeft = buttonLeft;
          const distanceToRight = screenWidth - (buttonLeft + buttonWidth);
          const distanceToTop = buttonTop;
          const distanceToBottom = screenHeight - (buttonTop + buttonHeight);

          const distances = [
            { edge: 'left', distance: distanceToLeft },
            { edge: 'right', distance: distanceToRight },
            { edge: 'top', distance: distanceToTop },
            { edge: 'bottom', distance: distanceToBottom },
          ];

          const nearestEdge = distances.reduce((prev, curr) => {
            return prev.distance < curr.distance ? prev : curr;
          });

          switch (nearestEdge.edge) {
            case 'left':
              x.set(-initialLeft);
              break;
            case 'right':
              x.set(screenWidth - buttonWidth - 16 - initialLeft);
              break;
            case 'top':
              y.set(16 - initialTop);
              break;
            case 'bottom':
              y.set(0);
              break;
            default:
              y.set(0);
          }
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 500"
          width="100%"
          height="100%"
          aria-labelledby="title desc"
          role="img"
        >
          <title id="title">Chat de Cuidado Personal Compacto</title>
          <desc id="desc">
            Interfaz optimizada para bienestar con elementos esenciales
          </desc>
          <g transform="translate(60 120)">
            <path
              d="M30 0h320a30 30 0 0 1 30 30v90a30 30 0 0 1-30 30H30a30 30 0 0 1-30-30V30A30 30 0 0 1 30 0z"
              fill="#fff"
            />
            <path
              d="M30 0L0 30v16h60L30 0z"
              fill="#fff"
              transform="translate(0 30)"
            />
            <foreignObject x="40" y="24" width="280" height="200">
              <div
                xmlns="http://www.w3.org/1999/xhtml"
                style={{
                  fontFamily: 'Arial',
                  fontSize: '32px',
                  color: '#6d4d58',
                  lineHeight: '1.4',
                  fontWeight: '500', // Semi-bold
                }}
              >
                {user ? (
                  planData ? (
                    'Hola, ¿en qué puedo ayudarte hoy?'
                  ) : (
                    'Crea un Plan para Asistirte'
                  )
                ) : (
                  'Inicia Sesión para asistirte.'
                )}
              </div>
            </foreignObject>
          </g>
          <g transform="translate(60 280)">
            <rect width="200" height="60" rx="30" fill="#fff" />
            <circle cx="60" cy="30" r="8" fill="#ff85a2">
              <animate
                attributeName="opacity"
                values="0;1;0"
                dur="1.5s"
                repeatCount="indefinite"
              />
            </circle>
            <circle cx="100" cy="30" r="8" fill="#ff85a2">
              <animate
                attributeName="opacity"
                values="0;1;0"
                dur="1.5s"
                repeatCount="indefinite"
                begin="0.3s"
              />
            </circle>
            <circle cx="140" cy="30" r="8" fill="#ff85a2">
              <animate
                attributeName="opacity"
                values="0;1;0"
                dur="1.5s"
                repeatCount="indefinite"
                begin="0.6s"
              />
            </circle>
          </g>
        </svg>
      </motion.button>

      <AnimatePresence>
        {isOpen &&
          (user ? (
            <ChatWindow
              key="chat-window"
              handlers={handlers}
              theme={theme}
              toggleChat={toggleChat}
              isSidebarOpen={isSidebarOpen}
              handleToggleSidebar={handleToggleSidebar}
              sidebarRef={sidebarRef}
              toggleButtonRef={toggleButtonRef}
              chats={chats}
              currentChatId={currentChatId}
              handleSelectChat={handleSelectChat}
              handleNewChat={handleNewChat}
              handleRenameChat={handleRenameChat}
              handleDeleteChat={handleDeleteChat}
              currentChat={currentChat}
              input={input}
              setInput={setInput}
              handleSend={handleSend}
              isLoading={isLoading}
            />
          ) : (
            showLogin && (
              <motion.div
                key="login-message"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.2 }}
                className={`fixed bottom-20 right-4 sm:right-auto sm:bottom-auto sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 z-50`}
              >
                <button
                  onClick={() => setShowLogin(false)}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-300 dark:hover:text-gray-100"
                  aria-label="Cerrar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <p className="text-center text-gray-800 dark:text-gray-200 mb-4">
                  Por favor, inicia sesión para utilizar el chat.
                </p>
                <button
                  onClick={login}
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
                >
                  Iniciar Sesión
                </button>
              </motion.div>
            )
          ))}
      </AnimatePresence>
    </div>
  );
};

export default AssistantChat;
