// src/App.js
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Sidebar from './Components/Sidebar/Sidebar';
import Dashboard from './Components/Dashboard/Dashboard';
import Footer from './Components/Footer/Footer';
import { ThemeProvider } from './Components/ThemeContext';
import { AuthProvider } from './Components/contexts/AuthContext';
import { PlanProvider } from './Components/contexts/PlanContext';
import { ChatProvider } from './Components/contexts/ChatContext';
import AssistantChat from './Components/assistant/AssistantChat';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // Disable refetch on window focus
      retry: 1, // Retry failed requests only once
    },
  },
});

const App = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AuthProvider>
          {/* Único lugar donde definimos el PlanProvider */}
          <PlanProvider>
            <ChatProvider>
              <div className="flex flex-col min-h-screen">
                {!isChatOpen && <Sidebar />}
                <Dashboard />
                <Footer />
                <AssistantChat isOpen={isChatOpen} toggleChat={toggleChat} />
              </div>
            </ChatProvider>
          </PlanProvider>
        </AuthProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} /> {/* Add DevTools */}
    </QueryClientProvider>
  );
};

export default App;
