// CanvasComponent.js
import React, { useRef, useEffect, useState } from 'react';
import { useTheme } from '../ThemeContext'; // Ajusta la ruta según tu ThemeProvider
import { FaTrash } from 'react-icons/fa'; // Importa los íconos necesarios excepto FaPlusCircle
import Draggable from 'react-draggable'; // Importa react-draggable
// Elimina la importación de saveEntry
// import { saveEntry } from '../Sidebar/formats/apiService';
import { usePlan } from '../contexts/PlanContext'; // Importa usePlan para obtener planId

const CanvasComponent = ({ cardData }) => {
  const canvasRef = useRef(null);
  const [context, setContext] = useState(null);
  const { theme } = useTheme();
  const { planId, addEntry } = usePlan(); // Extrae addEntry desde usePlan

  const [cards, setCards] = useState([]);
  const [notification, setNotification] = useState({ message: '', type: '' }); // Estado para notificaciones

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = canvas.parentElement.clientWidth;
    canvas.height = canvas.parentElement.clientHeight;

    const ctx = canvas.getContext('2d');
    setContext(ctx);

    // Dibujar la cuadrícula de fondo según el tema
    drawGrid(ctx, canvas.width, canvas.height, theme);
  }, [theme]);

  useEffect(() => {
    // Si se recibe nueva data para una tarjeta (cardData), crearla y agregarla al canvas
    if (cardData && Object.keys(cardData).length > 0) {
      const newCard = createCardFromData(cardData);
      setCards((prevCards) => [...prevCards, newCard]);
    }
  }, [cardData]);

  // Función para dibujar la cuadrícula de fondo
  const drawGrid = (ctx, width, height, theme) => {
    const gridSize = 20;
    const dotRadius = 1;

    ctx.fillStyle = theme === 'dark' ? '#0d0d0d' : '#f9f9f9';
    ctx.fillRect(0, 0, width, height);

    ctx.fillStyle = theme === 'dark' ? '#1a1a1a' : '#e0e0e0';

    for (let x = 0; x < width; x += gridSize) {
      for (let y = 0; y < height; y += gridSize) {
        ctx.beginPath();
        ctx.arc(x, y, dotRadius, 0, Math.PI * 2);
        ctx.fill();
      }
    }
  };

  // Formato de fecha para inputs datetime-local
  const formatDateForInput = (date) => {
    const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return localDate.toISOString().slice(0, 16);
  };

  // Parsear fecha y hora en formato datetime-local como hora local
  const parseDateTimeLocal = (dateTimeString) => {
    const [datePart, timePart] = dateTimeString.split('T');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);
    // Cambiado de Date.UTC a hora local
    return new Date(year, month - 1, day, hours, minutes);
  };

  // Crear una tarjeta basándose en la información recibida
  const createCardFromData = (data) => {
    const now = new Date();
    const formattedDate = formatDateForInput(now);
    let newCard = {
      id: Date.now(),
      type: data.tipo || 'Alimentación',
      title: data.titulo || '',
      date: formattedDate,
      position: { x: 0, y: 0 },
      saved: false,
      saving: false,
    };

    if (newCard.type === 'Ejercicio') {
      newCard.duration = data.datos?.duracion || '';
      newCard.caloriesBurned = data.datos?.caloriasQuemadas || '';
    } else if (newCard.type === 'Alimentación') {
      newCard.calories = data.datos?.calorias || '';
      newCard.proteins = data.datos?.proteinas || '';
      newCard.carbs = data.datos?.carbohidratos || '';
      newCard.sodium = data.datos?.sodio || '';
      newCard.fats = data.datos?.grasas || '';
    } else if (
      newCard.type === 'Estado de Ánimo' ||
      newCard.type === 'Estado Físico' ||
      newCard.type === 'Estado de Salud'
    ) {
      newCard.description = data.datos?.descripcion || '';
    }

    return newCard;
  };

  // Actualizar campos de una tarjeta por id
  const updateCard = (id, field, value) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === id ? { ...card, [field]: value } : card
      )
    );
  };

  // Eliminar tarjeta
  const removeCard = (id) => {
    setCards((prevCards) => prevCards.filter((card) => card.id !== id));
  };

  // Manejar el evento de arrastre para actualizar posición
  const handleDrag = (e, data, id) => {
    setCards((prevCards) =>
      prevCards.map((card) =>
        card.id === id ? { ...card, position: { x: data.x, y: data.y } } : card
      )
    );
  };

  // Mapeo de tipos de tarjetas a tipos de entrada en la API
  const typeMapping = {
    Ejercicio: 'workout',
    Alimentación: 'food',
    'Estado de Ánimo': 'mood',
    'Estado Físico': 'state',
    'Estado de Salud': 'state',
  };

  // Guardar tarjeta en la base de datos utilizando addEntry del contexto
  const handleSaveCard = async (cardId) => {
    const card = cards.find((c) => c.id === cardId);
    if (!card) return;

    if (card.saved || card.saving) {
      return;
    }

    updateCard(cardId, 'saving', true);

    if (!planId) {
      setNotification({
        message: 'No hay un plan activo. Por favor, crea un plan antes de guardar.',
        type: 'error',
      });
      updateCard(cardId, 'saving', false);
      return;
    }

    const entryType = typeMapping[card.type];
    let data = {};

    if (entryType === 'workout') {
      data = {
        workoutType: card.title,
        duration: Number(card.duration),
        caloriesBurned: Number(card.caloriesBurned),
      };
    } else if (entryType === 'food') {
      data = {
        food: card.title,
        nutritionDetails: {
          calories: card.calories,
          protein: card.proteins,
          carbohydrates: card.carbs,
          fat: card.fats,
          sodium: card.sodium,
        },
      };
    } else if (entryType === 'mood' || entryType === 'state') {
      data = {
        [entryType]: card.description,
      };
    }

    const timestamp = parseDateTimeLocal(card.date);

    const newEntry = {
      type: entryType,
      data: data,
      icons: [entryType],
      // planId: planId, // No es necesario incluir planId ya que addEntry lo gestiona
      timestamp: timestamp,
    };

    try {
      await addEntry(newEntry); // Usa addEntry desde el contexto
      updateCard(cardId, 'saved', true);
      updateCard(cardId, 'saving', false);
      setNotification({
        message: 'Entrada guardada exitosamente.',
        type: 'success',
      });
    } catch (error) {
      updateCard(cardId, 'saving', false);
      console.error('Error al guardar la entrada:', error);
      setNotification({
        message: 'Error al guardar la entrada. Por favor, intenta nuevamente.',
        type: 'error',
      });
    }
  };

  // Estilos dinámicos según el tema
  const inputClassName =
    theme === 'dark'
      ? 'bg-gray-800 text-white p-2 border border-gray-700 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500'
      : 'bg-white text-black p-2 border border-gray-300 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500';

  const cardClassName =
    theme === 'dark'
      ? 'bg-gradient-to-br from-gray-900 to-gray-800 p-4 border border-gray-700 shadow-xl rounded-md'
      : 'bg-gradient-to-br from-white to-gray-100 p-4 border border-gray-300 shadow-xl rounded-md';

  const labelClassName = 'block text-sm font-medium mb-1';

  return (
    <div className="canvas-container flex flex-col items-center w-full h-full relative">
      {/* Notificación */}
      {notification.message && (
        <div
          className={`p-2 mb-2 mt-4 rounded-md text-center max-w-lg mx-auto ${
            notification.type === 'success'
              ? 'bg-green-100 text-green-800'
              : 'bg-red-100 text-red-800'
          }`}
        >
          {notification.message}
        </div>
      )}

      {/* Contenedor del Canvas */}
      <div className="canvas-wrapper w-full h-full relative flex-1">
        <canvas ref={canvasRef} className="absolute top-0 left-0 w-full h-full" />

        {/* Renderizado de tarjetas draggable */}
        {cards.map((card) => (
          <Draggable
            key={card.id}
            position={card.position}
            onDrag={(e, data) => handleDrag(e, data, card.id)}
            bounds="parent"
            cancel=".no-drag"
          >
            <div className={`${cardClassName} w-72`}>
              <div className="mb-2">
                <h3 className="text-xl font-semibold text-center text-blue-500">
                  {card.type}
                </h3>
              </div>

              {/* Contenido si la tarjeta ya está guardada */}
              {card.saved ? (
                <div className="saved-content text-gray-200">
                  <div className="mb-2">
                    <p>
                      <span className="font-semibold">Título:</span> {card.title}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p>
                      <span className="font-semibold">Fecha y hora:</span>{' '}
                      {new Date(card.date).toLocaleString()}
                    </p>
                  </div>

                  {card.type === 'Ejercicio' && (
                    <>
                      <div className="mb-2">
                        <p>
                          <span className="font-semibold">Duración:</span> {card.duration} minutos
                        </p>
                      </div>
                      <div className="mb-2">
                        <p>
                          <span className="font-semibold">Calorías Quemadas:</span> {card.caloriesBurned} kcal
                        </p>
                      </div>
                    </>
                  )}

                  {card.type === 'Alimentación' && (
                    <>
                      <div className="mb-2">
                        <p>
                          <span className="font-semibold">Calorías:</span> {card.calories} kcal
                        </p>
                      </div>
                      <div className="mb-2">
                        <p>
                          <span className="font-semibold">Proteínas:</span> {card.proteins} g
                        </p>
                      </div>
                      <div className="mb-2">
                        <p>
                          <span className="font-semibold">Carbohidratos:</span> {card.carbs} g
                        </p>
                      </div>
                      <div className="mb-2">
                        <p>
                          <span className="font-semibold">Grasas:</span> {card.fats} g
                        </p>
                      </div>
                      <div className="mb-2">
                        <p>
                          <span className="font-semibold">Sodio:</span> {card.sodium} mg
                        </p>
                      </div>
                    </>
                  )}

                  {(card.type === 'Estado de Ánimo' ||
                    card.type === 'Estado Físico' ||
                    card.type === 'Estado de Salud') && (
                    <div className="mb-2">
                      <p>
                        <span className="font-semibold">Descripción:</span> {card.description}
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                // Contenido si la tarjeta NO está guardada (campos de entrada)
                <>
                  <div className="mb-2">
                    <label className={labelClassName}>Título</label>
                    <input
                      type="text"
                      value={card.title}
                      onChange={(e) => updateCard(card.id, 'title', e.target.value)}
                      placeholder={`Título de ${card.type}`}
                      className={inputClassName}
                    />
                  </div>
                  <div className="mb-2">
                    <label className={labelClassName}>Fecha y hora</label>
                    <input
                      type="datetime-local"
                      value={card.date}
                      onChange={(e) => updateCard(card.id, 'date', e.target.value)}
                      className={inputClassName}
                    />
                  </div>

                  {card.type === 'Ejercicio' && (
                    <>
                      <div className="mb-2">
                        <label className={labelClassName}>Duración (minutos)</label>
                        <input
                          type="number"
                          value={card.duration}
                          onChange={(e) => updateCard(card.id, 'duration', e.target.value)}
                          placeholder="Duración en minutos"
                          className={inputClassName}
                        />
                      </div>
                      <div className="mb-2">
                        <label className={labelClassName}>
                          Calorías Quemadas (kcal)
                        </label>
                        <input
                          type="number"
                          value={card.caloriesBurned}
                          onChange={(e) =>
                            updateCard(card.id, 'caloriesBurned', e.target.value)
                          }
                          placeholder="Calorías quemadas"
                          className={inputClassName}
                        />
                      </div>
                    </>
                  )}

                  {card.type === 'Alimentación' && (
                    <>
                      <div className="mb-2">
                        <label className={labelClassName}>Calorías (kcal)</label>
                        <input
                          type="number"
                          value={card.calories}
                          onChange={(e) =>
                            updateCard(card.id, 'calories', e.target.value)
                          }
                          placeholder="Calorías consumidas"
                          className={inputClassName}
                        />
                      </div>
                      <div className="mb-2">
                        <label className={labelClassName}>Proteínas (g)</label>
                        <input
                          type="number"
                          value={card.proteins}
                          onChange={(e) =>
                            updateCard(card.id, 'proteins', e.target.value)
                          }
                          placeholder="Proteínas en gramos"
                          className={inputClassName}
                        />
                      </div>
                      <div className="mb-2">
                        <label className={labelClassName}>
                          Carbohidratos (g)
                        </label>
                        <input
                          type="number"
                          value={card.carbs}
                          onChange={(e) =>
                            updateCard(card.id, 'carbs', e.target.value)
                          }
                          placeholder="Carbohidratos en gramos"
                          className={inputClassName}
                        />
                      </div>
                      <div className="mb-2">
                        <label className={labelClassName}>Grasas (g)</label>
                        <input
                          type="number"
                          value={card.fats}
                          onChange={(e) => updateCard(card.id, 'fats', e.target.value)}
                          placeholder="Grasas en gramos"
                          className={inputClassName}
                        />
                      </div>
                      <div className="mb-2">
                        <label className={labelClassName}>Sodio (mg)</label>
                        <input
                          type="number"
                          value={card.sodium}
                          onChange={(e) => updateCard(card.id, 'sodium', e.target.value)}
                          placeholder="Sodio en miligramos"
                          className={inputClassName}
                        />
                      </div>
                    </>
                  )}

                  {(card.type === 'Estado de Ánimo' ||
                    card.type === 'Estado Físico' ||
                    card.type === 'Estado de Salud') && (
                    <>
                      <div className="mb-2">
                        <label className={labelClassName}>Descripción</label>
                        <textarea
                          value={card.description}
                          onChange={(e) =>
                            updateCard(card.id, 'description', e.target.value)
                          }
                          placeholder={`Describe tu ${card.type.toLowerCase()}`}
                          className={`${inputClassName} resize-none h-24`}
                        />
                      </div>
                    </>
                  )}

                  {/* Botones de acción */}
                  <div className="flex justify-end mt-4 space-x-2">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleSaveCard(card.id);
                      }}
                      className={`no-drag px-4 py-2 rounded-md shadow-md transition-colors ${
                        card.saved || card.saving
                          ? 'bg-gray-500 text-white cursor-not-allowed'
                          : 'bg-green-600 hover:bg-green-700 text-white'
                      }`}
                      disabled={card.saved || card.saving}
                    >
                      {card.saving ? 'Guardando...' : 'Guardar'}
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        removeCard(card.id);
                      }}
                      className="no-drag bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-md shadow-md transition-colors"
                    >
                      <FaTrash className="inline-block mr-1" />
                      Eliminar
                    </button>
                  </div>
                </>
              )}
            </div>
          </Draggable>
        ))}
      </div>

      {/* 
        Botón flotante para agregar nuevas tarjetas eliminado
        Se ha eliminado el bloque de código relacionado con el botón flotante
      */}
    </div>
  );
};

export default CanvasComponent;
