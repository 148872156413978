// src/components/sidebar/formats/storage.js

/**
 * Obtiene datos guardados en localStorage o retorna el valor por defecto.
 * @param {string} key - La clave en localStorage.
 * @param {any} defaultValue - Valor por defecto si no existe la clave.
 * @returns {any} - Los datos guardados o el valor por defecto.
 */
export const getSavedData = (key, defaultValue) => {
  const saved = localStorage.getItem(key);
  if (saved) {
    try {
      return JSON.parse(saved);
    } catch (error) {
      console.error(`Error al parsear datos de localStorage para la clave "${key}":`, error);
      return defaultValue;
    }
  }
  return defaultValue;
};

/**
 * Guarda datos en localStorage.
 * @param {string} key - La clave en localStorage.
 * @param {any} data - Los datos a guardar.
 */
export const saveData = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    console.error(`Error al guardar datos en localStorage para la clave "${key}":`, error);
  }
};

/**
 * Elimina datos de localStorage.
 * @param {string} key - La clave en localStorage.
 */
export const removeData = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error al eliminar datos de localStorage para la clave "${key}":`, error);
  }
};
