// src/components/EntrySummary.js
import React, { useState, useMemo } from 'react';
import { usePlan } from '../../contexts/PlanContext';  // Ajusta la ruta si difiere
import { useTheme } from '../../ThemeContext';          // Ajusta la ruta si difiere
import EntrySummaryView from './EntrySummaryView';      // Ajusta la ruta si difiere
import EntryDetailModal from './EntryDetailModal';      // Ajusta la ruta si difiere

/**
 * ======================
 *   Helper Functions
 * ======================
 */

// Convierte un objeto Date o una cadena de fecha a "YYYY-MM-DD"
const getDayFromTimestamp = (timestamp) => {
  if (!timestamp) return 'Unknown';
  const dateObj = new Date(timestamp);
  // Asegura que se tome la fecha en la zona horaria del dispositivo
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Convierte a "DD/MM/YYYY HH:MM"
const getDateTimeFromTimestamp = (timestamp) => {
  if (!timestamp) return 'Desconocido';
  const dateObj = new Date(timestamp);
  const date = dateObj.toLocaleDateString();
  const time = dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  return `${date} ${time}`;
};

// Obtiene un array de fechas únicas a partir de un array de entradas
const getUniqueDates = (entries) => {
  const dates = entries.map((entry) => getDayFromTimestamp(entry.timestamp));
  return [...new Set(dates)];
};

// Agrupa las entradas por día y suma los campos especificados
const getDailyTotals = (
  entries,
  fields = [],
  dataAccessor = (entry, field) => entry.data[field]
) => {
  const dailyMap = {};

  entries.forEach((entry) => {
    const day = getDayFromTimestamp(entry.timestamp);
    if (!dailyMap[day]) {
      dailyMap[day] = {};
      // Inicializa los campos a 0
      fields.forEach((field) => {
        dailyMap[day][field] = 0;
      });
    }
    fields.forEach((field) => {
      const value = Number(dataAccessor(entry, field));
      if (!isNaN(value)) {
        dailyMap[day][field] += value;
      }
    });
  });

  return dailyMap;
};

// Calcula la distribución porcentual de Proteínas, Carbs y Grasas
const getMacroDistribution = (calorias, proteinas, carbohidratos, grasas) => {
  const totalCals = calorias || 0;
  const pCals = (proteinas || 0) * 4;
  const cCals = (carbohidratos || 0) * 4;
  const gCals = (grasas || 0) * 9;

  const pPercent = totalCals > 0 ? (pCals / totalCals) * 100 : 0;
  const cPercent = totalCals > 0 ? (cCals / totalCals) * 100 : 0;
  const gPercent = totalCals > 0 ? (gCals / totalCals) * 100 : 0;

  return { pPercent, cPercent, gPercent };
};

const EntrySummary = () => {
  /**
   * ======================
   *    Estados locales
   * ======================
   */
  const [isOpen, setIsOpen] = useState(false);
  const [foodView, setFoodView] = useState('total');
  const [workoutView, setWorkoutView] = useState('total');
  const [selectedEntry, setSelectedEntry] = useState(null);

  /**
   * ======================
   *    Contextos
   * ======================
   */
  const { entries, deleteEntry } = usePlan();   // <- Aquí tomamos las entradas en tiempo real
  const { theme } = useTheme();                // Suponiendo que tengas un ThemeContext

  /**
   * ======================
   *   Estadísticas globales
   * ======================
   */
  const totalEntries = entries.length;

  // Agrupación por tipo
  const entriesByType = useMemo(() => {
    return entries.reduce((acc, entry) => {
      acc[entry.type] = acc[entry.type] || [];
      acc[entry.type].push(entry);
      return acc;
    }, {});
  }, [entries]);

  /**
   * ======================
   *    ALIMENTACIÓN
   * ======================
   */
  const foodEntries = useMemo(() => entriesByType.food || [], [entriesByType]);

  // Totales globales de alimentación
  const foodTotals = useMemo(() => {
    const totals = {
      calorias: 0,
      proteinas: 0,
      carbohidratos: 0,
      grasas: 0,
      sodio: 0,
    };
    foodEntries.forEach((entry) => {
      const n = entry.data?.nutritionDetails || {};
      totals.calorias += Number(n.calories) || 0;
      totals.proteinas += Number(n.protein) || 0;
      totals.carbohidratos += Number(n.carbohydrates) || 0;
      totals.grasas += Number(n.fat) || 0;
      totals.sodio += Number(n.sodium) || 0;
    });
    return totals;
  }, [foodEntries]);

  // Totales diarios (por día)
  const foodDailyMap = useMemo(
    () =>
      getDailyTotals(
        foodEntries,
        ['calories', 'protein', 'carbohydrates', 'fat', 'sodium'],
        (entry, field) => entry.data?.nutritionDetails?.[field] || 0
      ),
    [foodEntries]
  );

  // Días únicos para alimentación
  const uniqueFoodDates = useMemo(() => Object.keys(foodDailyMap), [foodDailyMap]);
  const foodUniqueDaysCount = uniqueFoodDates.length;

  // Suma de métricas diarias
  const sumDailyMetrics = useMemo(() => {
    let sumDailyCalories = 0,
      sumDailyProteins = 0,
      sumDailyCarbs = 0,
      sumDailyFats = 0,
      sumDailySodium = 0;

    uniqueFoodDates.forEach((day) => {
      sumDailyCalories += foodDailyMap[day].calories;
      sumDailyProteins += foodDailyMap[day].protein;
      sumDailyCarbs += foodDailyMap[day].carbohydrates;
      sumDailyFats += foodDailyMap[day].fat;
      sumDailySodium += foodDailyMap[day].sodium;
    });

    return {
      sumDailyCalories,
      sumDailyProteins,
      sumDailyCarbs,
      sumDailyFats,
      sumDailySodium,
    };
  }, [foodDailyMap, uniqueFoodDates]);

  // Promedio diario
  const foodDailyAvg = useMemo(() => {
    if (foodUniqueDaysCount > 0) {
      return {
        calorias: (sumDailyMetrics.sumDailyCalories / foodUniqueDaysCount).toFixed(1),
        proteinas: (sumDailyMetrics.sumDailyProteins / foodUniqueDaysCount).toFixed(1),
        carbohidratos: (sumDailyMetrics.sumDailyCarbs / foodUniqueDaysCount).toFixed(1),
        grasas: (sumDailyMetrics.sumDailyFats / foodUniqueDaysCount).toFixed(1),
        sodio: (sumDailyMetrics.sumDailySodium / foodUniqueDaysCount).toFixed(1),
      };
    }
    // Si no hay días, usar totales globales
    return { ...foodTotals };
  }, [foodUniqueDaysCount, sumDailyMetrics, foodTotals]);

  // Distribución de macros
  const macroDistribution = useMemo(() => {
    return getMacroDistribution(
      foodTotals.calorias,
      foodTotals.proteinas,
      foodTotals.carbohidratos,
      foodTotals.grasas
    );
  }, [foodTotals]);

  // Lista de alimentos
  const foodList = useMemo(() => {
    return foodEntries.map((entry) => {
      const fName = entry.data?.food || 'Sin nombre';
      const timestamp = entry.timestamp;
      const dateTime = getDateTimeFromTimestamp(timestamp);
      return {
        id: entry._id,
        type: 'food',
        name: fName,
        dateTime,
        data: entry.data,
      };
    });
  }, [foodEntries]);

  // Estadísticas finales de alimentación
  const foodStats = useMemo(
    () => ({
      totalCount: foodEntries.length,
      totals: foodTotals,
      daily: foodDailyAvg,
      uniqueDays: foodUniqueDaysCount,
    }),
    [foodEntries.length, foodTotals, foodDailyAvg, foodUniqueDaysCount]
  );

  /**
   * ======================
   *     EJERCICIO
   * ======================
   */
  const workoutEntries = useMemo(
    () => entriesByType.workout || [],
    [entriesByType]
  );

  // Para los totales diarios de ejercicio
  const workoutDailyMap = useMemo(() => {
    return getDailyTotals(
      workoutEntries,
      ['duration', 'caloriesBurned'],
      (entry, field) => entry.data?.[field] || 0
    );
  }, [workoutEntries]);

  const workoutStats = useMemo(() => {
    let totalDuracion = 0;
    let totalCaloriasQuemadas = 0;

    workoutEntries.forEach((entry) => {
      const w = entry.data;
      totalDuracion += Number(w?.duration) || 0;
      totalCaloriasQuemadas += Number(w?.caloriesBurned) || 0;
    });

    const uniqueWorkoutDates = Object.keys(workoutDailyMap);
    const uniqueWorkoutDaysCount = uniqueWorkoutDates.length;

    let sumDuration = 0,
      sumBurned = 0;

    uniqueWorkoutDates.forEach((day) => {
      sumDuration += workoutDailyMap[day].duration;
      sumBurned += workoutDailyMap[day].caloriesBurned;
    });

    const workoutDaily =
      uniqueWorkoutDaysCount > 0
        ? {
            duracion: (sumDuration / uniqueWorkoutDaysCount).toFixed(1),
            caloriasQuemadas: (sumBurned / uniqueWorkoutDaysCount).toFixed(1),
          }
        : {
            duracion: totalDuracion,
            caloriasQuemadas: totalCaloriasQuemadas,
          };

    return {
      count: workoutEntries.length,
      totalDuracion,
      totalCaloriasQuemadas,
      uniqueDays: uniqueWorkoutDaysCount,
      daily: workoutDaily,
    };
  }, [workoutEntries, workoutDailyMap]);

  // Lista de ejercicios
  const workoutList = useMemo(() => {
    return workoutEntries.map((entry) => {
      const workoutType = entry.data?.workoutType || 'Tipo Desconocido';
      const timestamp = entry.timestamp;
      const dateTime = getDateTimeFromTimestamp(timestamp);
      return {
        id: entry._id,
        type: 'workout',
        workoutType,
        dateTime,
        data: entry.data,
      };
    });
  }, [workoutEntries]);

  /**
   * ======================
   *    ESTADO DE ÁNIMO
   * ======================
   */
  const moodEntries = useMemo(() => entriesByType.mood || [], [entriesByType]);

  const moodStats = useMemo(() => {
    const moodCount = {};
    moodEntries.forEach((entry) => {
      const titulo = entry.data?.mood || 'Sin título';
      moodCount[titulo] = (moodCount[titulo] || 0) + 1;
    });
    const moodUniqueDays = getUniqueDates(moodEntries).length;
    return {
      count: moodEntries.length,
      moodCount,
      uniqueDays: moodUniqueDays,
    };
  }, [moodEntries]);

  // Lista de ánimos
  const moodList = useMemo(() => {
    return moodEntries.map((entry) => {
      const mood = entry.data?.mood || 'Estado sin título';
      const timestamp = entry.timestamp;
      const dateTime = getDateTimeFromTimestamp(timestamp);
      return {
        id: entry._id,
        type: 'mood',
        mood,
        dateTime,
        data: entry.data,
      };
    });
  }, [moodEntries]);

  /**
   * ======================
   *    ESTADO FÍSICO
   * ======================
   */
  const stateEntries = useMemo(() => entriesByType.state || [], [entriesByType]);

  const stateStats = useMemo(() => {
    const stateList = stateEntries.map(
      (entry) => entry.data?.state || 'Estado sin título'
    );
    const stateUniqueDays = getUniqueDates(stateEntries).length;
    return {
      count: stateEntries.length,
      stateList,
      uniqueDays: stateUniqueDays,
    };
  }, [stateEntries]);

  // Lista de estado físico
  const physicalStateList = useMemo(() => {
    return stateEntries.map((entry) => {
      const state = entry.data?.state || 'Estado sin título';
      const timestamp = entry.timestamp;
      const dateTime = getDateTimeFromTimestamp(timestamp);
      return {
        id: entry._id,
        type: 'state',
        state,
        dateTime,
        data: entry.data,
      };
    });
  }, [stateEntries]);

  /**
   * ======================
   *    Cálculo de HOY
   * ======================
   */
  // Obtenemos el string de la fecha de hoy en formato "YYYY-MM-DD" basado en la fecha del dispositivo
  const today = getDayFromTimestamp(new Date());

  // Calorías ingeridas hoy: suma de todas las calorías de las entradas de alimentos con fecha hoy
  const todayFoodCalories = useMemo(() => {
    return foodEntries
      .filter((entry) => getDayFromTimestamp(entry.timestamp) === today)
      .reduce((sum, entry) => sum + Number(entry.data?.nutritionDetails?.calories || 0), 0);
  }, [foodEntries, today]);

  // Calorías quemadas hoy: suma de todas las calorías quemadas de las entradas de ejercicio con fecha hoy
  const todayWorkoutCalories = useMemo(() => {
    return workoutEntries
      .filter((entry) => getDayFromTimestamp(entry.timestamp) === today)
      .reduce((sum, entry) => sum + Number(entry.data?.caloriesBurned || 0), 0);
  }, [workoutEntries, today]);

  /**
   * ======================
   *    Manejo del Resumen
   * ======================
   */
  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
    if (isOpen) {
      // Si se va a cerrar, resetea vistas
      setFoodView('total');
      setWorkoutView('total');
      setSelectedEntry(null);
    }
  };

  const handleSelectEntry = (entry) => {
    setSelectedEntry(entry);
  };

  const handleCloseModal = () => {
    setSelectedEntry(null);
  };

  /**
   * ======================
   *    PREVIEW COUNTERS
   * ======================
   */
  const previewCounters = useMemo(
    () => [
      { type: 'food', count: foodStats.totalCount, label: 'Alimentación' },
      { type: 'workout', count: workoutStats.count, label: 'Ejercicio' },
      { type: 'mood', count: moodStats.count, label: 'Ánimo' },
      { type: 'state', count: stateStats.count, label: 'Estado Físico' },
    ],
    [foodStats, workoutStats, moodStats, stateStats]
  );

  return (
    <>
      <EntrySummaryView
        isOpen={isOpen}
        toggleIsOpen={toggleIsOpen}
        previewCounters={previewCounters}
        totalEntries={totalEntries}
        // Estadísticas
        foodStats={foodStats}
        workoutStats={workoutStats}
        moodStats={moodStats}
        stateStats={stateStats}
        // Vistas
        foodView={foodView}
        setFoodView={setFoodView}
        workoutView={workoutView}
        setWorkoutView={setWorkoutView}
        // Tema
        theme={theme}
        // Listas
        foodList={foodList}
        workoutList={workoutList}
        moodList={moodList}
        physicalStateList={physicalStateList}
        // Macro
        macroDistribution={macroDistribution}
        // Funciones para eliminar y seleccionar
        deleteEntry={deleteEntry}
        onSelectEntry={handleSelectEntry}
        // Calorías de hoy
        todayFoodCalories={todayFoodCalories}
        todayWorkoutCalories={todayWorkoutCalories}
      />

      {/* Modal de detalles */}
      {selectedEntry && (
        <EntryDetailModal
          entry={selectedEntry}
          onClose={handleCloseModal}
          theme={theme}
        />
      )}
    </>
  );
};

export default EntrySummary;
