// src/Sidebar/formats/apiService.js

// === URL base de la API ===
const API_BASE_URL = 'https://api.carendary.com/api';

// === Funciones para manejar las métricas de salud ===

/**
 * Obtener las métricas de salud desde la API.
 *
 * @returns {Promise<object|null>} - Datos de métricas de salud o null en caso de error.
 */
export const getHealthMetrics = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/healthmetrics`, {
      credentials: 'include',
    });
    if (response.ok) {
      const data = await response.json();
      return data; // Asegúrate de que el formato de los datos coincide con lo esperado
    } else {
      console.error('Error al obtener las métricas de salud:', response.statusText);
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al obtener las métricas de salud');
    }
  } catch (error) {
    console.error('Error al obtener las métricas de salud:', error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};

/**
 * Guardar las métricas de salud sin debounce.
 *
 * @param {object} updatedData - Datos actualizados de métricas de salud.
 * @returns {Promise<object|null>} - Datos guardados o null en caso de error.
 */
export const saveHealthMetrics = async (updatedData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/healthmetrics`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(updatedData),
    });
    if (response.ok) {
      const data = await response.json();
      return data; // Devuelve las métricas guardadas
    } else {
      console.error('Error al guardar las métricas de salud:', response.statusText);
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al guardar las métricas de salud');
    }
  } catch (error) {
    console.error('Error al guardar las métricas de salud:', error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};

// === Funciones para manejar los planes ===

/**
 * Obtener los planes desde la API.
 *
 * @returns {Promise<Array|null>} - Array de planes o null en caso de error.
 */
export const getPlans = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/plans`, {
      credentials: 'include',
    });
    if (response.ok) {
      const data = await response.json();
      return data; // Devuelve un array de planes
    } else {
      console.error('Error al obtener los planes:', response.statusText);
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al obtener los planes');
    }
  } catch (error) {
    console.error('Error al obtener los planes:', error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};

/**
 * Guardar un plan sin debounce (POST /plans).
 *
 * @param {object} planData - Datos del plan a guardar.
 * @returns {Promise<object|null>} - Plan guardado o null en caso de error.
 */
export const savePlan = async (planData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/plans`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(planData),
    });
    if (response.ok) {
      const data = await response.json();
      return data; // Devuelve el plan guardado
    } else {
      console.error('Error al guardar el plan:', response.statusText);
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al guardar el plan');
    }
  } catch (error) {
    console.error('Error al guardar el plan:', error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};

/**
 * Actualizar un plan existente (PUT /plans/:planId).
 *
 * @param {string} planId - ID del plan a actualizar.
 * @param {object} planData - Datos actualizados del plan.
 * @returns {Promise<object>} - El plan actualizado.
 */
export const updatePlan = async (planId, planData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/plans/${planId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(planData),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al actualizar el plan');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error al actualizar el plan:', error);
    throw error;
  }
};

/**
 * Eliminar las métricas de salud desde la API.
 *
 * @returns {Promise<boolean>} - true si la eliminación fue exitosa, false en caso contrario.
 */
export const deleteHealthMetrics = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/healthmetrics`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (response.ok) {
      console.log('Métricas eliminadas de la base de datos');
      return true;
    } else {
      console.error('Error al eliminar las métricas de salud:', response.statusText);
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al eliminar las métricas de salud');
    }
  } catch (error) {
    console.error('Error al eliminar las métricas de salud:', error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};

/**
 * Eliminar un plan específico desde la API (DELETE /plans/:planId).
 *
 * @param {string} planId - ID del plan a eliminar.
 * @returns {Promise<boolean>} - true si la eliminación fue exitosa, false en caso contrario.
 */
export const deletePlan = async (planId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/plans/${planId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (response.ok) {
      console.log('Plan eliminado de la base de datos');
      return true;
    } else {
      console.error('Error al eliminar el plan:', response.statusText);
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al eliminar el plan');
    }
  } catch (error) {
    console.error('Error al eliminar el plan:', error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};

// === Funciones para manejar las entradas (Entries) ===

/**
 * Obtener las entradas de un plan en un rango de fechas específico.
 *
 * @param {string} planId - ID del plan.
 * @param {Date} startDate - Fecha de inicio del rango.
 * @param {Date} endDate - Fecha de fin del rango.
 * @returns {Promise<Array|null>} - Array de entradas o null en caso de error.
 */
export const getEntries = async (planId, startDate, endDate) => {
  try {
    const response = await fetch(
      `${API_BASE_URL}/entries/plan/${planId}?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
      {
        credentials: 'include',
      }
    );
    if (response.ok) {
      const data = await response.json();
      return data; // Devuelve un array de entradas
    } else {
      console.error('Error al obtener las entradas:', response.statusText);
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al obtener las entradas');
    }
  } catch (error) {
    console.error('Error al obtener las entradas:', error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};

/**
 * Guardar una entrada sin debounce.
 *
 * @param {object} entryData - Datos de la entrada a guardar.
 * @returns {Promise<object|null>} - Entrada guardada o null en caso de error.
 */
export const saveEntry = async (entryData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/entries`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(entryData),
    });
    if (response.ok) {
      const data = await response.json();
      return data; // Devuelve la entrada guardada
    } else {
      console.error('Error al guardar la entrada:', response.statusText);
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al guardar la entrada');
    }
  } catch (error) {
    console.error('Error al guardar la entrada:', error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};

/**
 * Actualizar una entrada existente sin debounce.
 *
 * @param {string} entryId - ID de la entrada a actualizar.
 * @param {object} updatedData - Datos actualizados de la entrada.
 * @returns {Promise<object|null>} - Entrada actualizada o null en caso de error.
 */
export const updateEntry = async (entryId, updatedData) => {
  try {
    const response = await fetch(`${API_BASE_URL}/entries/${entryId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(updatedData),
    });
    if (response.ok) {
      const data = await response.json();
      return data; // Devuelve la entrada actualizada
    } else {
      console.error('Error al actualizar la entrada:', response.statusText);
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error al actualizar la entrada');
    }
  } catch (error) {
    console.error('Error al actualizar la entrada:', error);
    throw error; // Propaga el error para manejarlo en el frontend
  }
};

/**
 * Eliminar una entrada específica desde la API (DELETE /entries/:entryId).
 *
 * @param {string} entryId - ID de la entrada a eliminar.
 * @returns {Promise<object>} - Objeto con información sobre el éxito de la operación.
 */
export const deleteEntry = async (entryId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/entries/${entryId}`, {
      method: 'DELETE',
      credentials: 'include',
    });

    if (response.ok) {
      console.log('Entrada eliminada de la base de datos');
      return { success: true };
    } else {
      // Intentar parsear el mensaje de error del servidor
      let errorMessage = response.statusText;
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (parseError) {
        // No se pudo parsear el error, mantener statusText
      }
      console.error('Error al eliminar la entrada:', errorMessage);
      return { success: false, message: errorMessage };
    }
  } catch (error) {
    console.error('Error al eliminar la entrada:', error);
    return { success: false, message: error.message };
  }
};
