// src/components/sidebar/formats/useDataManagement.js

import { useState, useEffect, useCallback, useRef } from 'react';
import { getSavedData, saveData, removeData } from './storage';
import { useAuth } from '../../contexts/AuthContext';
import { usePlan } from '../../contexts/PlanContext'; // Importar el hook usePlan

/**
 * Hook personalizado para gestionar datos de salud y planes.
 * @param {object} initialData - Datos iniciales para el estado.
 * @returns {object} - Estado y funciones para gestionar los datos.
 */
const useDataManagement = (initialData) => {
  const { user, loading } = useAuth();
  const {
    getHealthMetrics,
    saveHealthMetrics,
    deleteHealthMetrics,
    getPlans,
    savePlan,
    updatePlan,
    deletePlan,
  } = usePlan(); // Desestructurar las funciones del contexto

  const [savedData, setSavedData] = useState(() => {
    return getSavedData('healthMetrics', initialData);
  });
  const [formData, setFormData] = useState(savedData);
  const [isSaved, setIsSaved] = useState(true);
  const [isSynced, setIsSynced] = useState(false);
  const [error, setError] = useState(null); // Definir estado para error

  const prevUserRef = useRef();
  const hasFetchedData = useRef(false);

  // Maneja la carga combinada (métricas + plan)
  const fetchData = useCallback(async () => {
    try {
      // 1) Cargar métricas
      const healthData = await getHealthMetrics(); // Obtener métricas desde el contexto
      // 2) Cargar planes
      const plansData = await getPlans(); // Obtener planes desde el contexto

      let combined = { ...initialData };
      if (healthData) {
        combined = { ...combined, ...healthData };
      }
      // Suponiendo que solo hay 1 plan o tomas el primero
      if (plansData && plansData.length > 0) {
        const plan = plansData[0]; // Ajustar según tu caso
        // Asegurar que las fechas existen antes de intentar dividirlas
        combined = {
          ...combined,
          _planId: plan._id, // Para saber si ya existe
          startDate: plan.start ? plan.start.split('T')[0] : '',
          endDate: plan.end ? plan.end.split('T')[0] : '',
          fitnessLevel: plan.fitnessLevel,
          caloricIntake: plan.caloricIntake,
          useCreatine: plan.useCreatine,
        };
      }

      setFormData(combined);
      saveData('healthMetrics', combined);
      setSavedData(combined);
      setIsSynced(true);
    } catch (error) {
      console.error('Error combinando métricas/plan:', error);
      setError('Error al combinar métricas y planes. Por favor, intenta nuevamente.');
    }
  }, [getHealthMetrics, getPlans, initialData]);

  useEffect(() => {
    if (user && !loading && !hasFetchedData.current) {
      fetchData();
      hasFetchedData.current = true;
    }
  }, [user, loading, fetchData]);

  useEffect(() => {
    const isDataChanged = JSON.stringify(formData) !== JSON.stringify(savedData);
    setIsSaved(!isDataChanged);
  }, [formData, savedData]);

  // Si el usuario cierra sesión, limpiar
  useEffect(() => {
    const prevUser = prevUserRef.current;
    if (prevUser && !user && !loading) {
      removeData('healthMetrics');
      setFormData(initialData);
      setSavedData(initialData);
      setIsSaved(true);
      setIsSynced(false);
      hasFetchedData.current = false;
      setError(null); // Resetear error al cerrar sesión
    } else if (!prevUser && user && !loading) {
      hasFetchedData.current = false;
    }
    prevUserRef.current = user;
  }, [user, loading, initialData]);

  /**
   * Guarda tanto métricas de salud como plan
   * @param {object} updatedData - Datos actualizados del formulario.
   */
  const saveFormData = async (updatedData) => {
    // Guarda en localStorage
    saveData('healthMetrics', updatedData);
    setFormData(updatedData);
    setSavedData(updatedData);
    setIsSaved(true);
    setError(null); // Resetear error al iniciar guardado

    if (!user) {
      return; // No llamamos API si el user no está logueado
    }

    try {
      // SEPARAR CAMPOS de Métricas vs Plan
      const {
        // Métricas
        dob,
        sex,
        weight,
        height,
        activityFactor,
        neck,
        waist,
        hip,
        bmi,
        bodyFat,
        tdee,
        bmr,
        age,
        bmiPercentile,

        // Plan
        _planId,
        startDate,
        endDate,
        fitnessLevel,
        caloricIntake,
        useCreatine,
      } = updatedData;

      // 1) Guardar Métricas en /healthmetrics
      const healthMetricsPayload = {
        dob,
        sex,
        weight,
        height,
        activityFactor,
        neck,
        waist,
        hip,
        bmi,
        bodyFat,
        tdee,
        bmr,
        age,
        bmiPercentile,
      };
      const savedHealthMetrics = await saveHealthMetrics(healthMetricsPayload); // Usar contexto

      // 2) Guardar/Actualizar Plan en /plans
      const planPayload = {
        start: startDate ? new Date(startDate).toISOString() : null, // Convertir a ISO
        end: endDate ? new Date(endDate).toISOString() : null, // Convertir a ISO
        fitnessLevel,
        caloricIntake,
        useCreatine,
      };

      let savedPlan = null;
      if (_planId) {
        // Si ya existe un plan => PUT /plans/:id
        savedPlan = await updatePlan(_planId, planPayload); // Usar contexto
      } else {
        // Sino => POST /plans
        savedPlan = await savePlan(planPayload); // Usar contexto
      }

      // Combinar respuestas (opcional)
      const combined = {
        ...updatedData,
        ...savedHealthMetrics,
      };
      if (savedPlan && savedPlan._id) {
        combined._planId = savedPlan._id;
        combined.startDate = savedPlan.start ? savedPlan.start.split('T')[0] : '';
        combined.endDate = savedPlan.end ? savedPlan.end.split('T')[0] : '';
        combined.fitnessLevel = savedPlan.fitnessLevel;
        combined.caloricIntake = savedPlan.caloricIntake;
        combined.useCreatine = savedPlan.useCreatine;
      }

      // Guardar en local de nuevo
      saveData('healthMetrics', combined);
      setFormData(combined);
      setSavedData(combined);
      setIsSynced(true);
    } catch (error) {
      console.error('Error al guardar los datos combinados:', error);
      setError('Error al guardar los datos. Por favor, intenta nuevamente.');
    }
  };

  /**
   * Eliminar tanto métricas como plan (si se requiere)
   * @param {boolean} isPlanActive - Indica si hay un plan activo.
   */
  const handleDelete = async (isPlanActive) => {
    if (isPlanActive) {
      alert('No puedes eliminar tus datos mientras exista un plan activo. Por favor, elimina el plan primero.');
      return;
    }

    // Limpieza en front
    setFormData(initialData);
    setSavedData(initialData);
    removeData('healthMetrics');
    setIsSaved(true);
    setError(null); // Resetear error al eliminar

    if (!user) {
      return;
    }

    try {
      await deleteHealthMetrics(); // Usar contexto

      // Si hay _planId en formData, elimínalo también
      if (formData._planId) {
        await deletePlan(formData._planId); // Usar contexto
      }
      setIsSynced(false);
    } catch (error) {
      console.error('Error al eliminar métricas/plan:', error);
      setError('Error al eliminar los datos. Por favor, intenta nuevamente.');
    }
  };

  return {
    formData,
    setFormData,
    savedData,
    setSavedData,
    isSaved,
    setIsSaved,
    handleDelete,
    saveFormData,
    user,
    loading,
    isSynced,
    setIsSynced,
    error, // Añadido para manejar errores
  };
};

export default useDataManagement;
