import React, { useState } from 'react';
import { differenceInDays } from 'date-fns';
import { usePlan } from '../contexts/PlanContext';
import { useTheme } from '../ThemeContext';

// Importa íconos profesionales (por ejemplo, Material Design Icons o Font Awesome)
import {
  MdExpandMore,
  MdExpandLess,
  MdDateRange,
  MdArrowDropUp,
  MdArrowDropDown,
  MdRemove,
  MdFastfood,
  MdLocalFireDepartment,
} from 'react-icons/md';

// Función para formatear las diferencias con íconos y colores
function formatDiff(diffValue, label, isPercent = false) {
  const sign = diffValue > 0 ? '+' : diffValue < 0 ? '-' : '';
  let icon = <MdRemove className="inline" />; // ícono neutro

  if (diffValue > 0) {
    icon = <MdArrowDropUp className="inline" />;
  } else if (diffValue < 0) {
    icon = <MdArrowDropDown className="inline" />;
  }

  const colorClass =
    diffValue > 0
      ? 'text-green-500 dark:text-green-300'
      : diffValue < 0
      ? 'text-red-500 dark:text-red-300'
      : 'text-gray-500 dark:text-gray-400';

  const absDiff = Number(Math.abs(diffValue).toFixed(1)); // Asegura que sea un número

  return (
    <div
      className={
        'flex items-center gap-1 text-sm cursor-pointer p-2 rounded-md ' +
        'hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-300'
      }
    >
      <span className={colorClass}>
        {icon} {sign}
        {absDiff}
        {isPercent ? '%' : ''} {label}
      </span>
    </div>
  );
}

// Función para mapear el objetivo calórico a su descripción completa
function getCaloricIntakeLabel(value) {
  if (!value) return 'Recomposición';
  const intakeLower = value.toLowerCase();
  if (intakeLower.includes('def')) return 'Definición';
  if (intakeLower.includes('vol')) return 'Volumen';
  return 'Recomposición';
}

// Función para mapear el sexo a su descripción completa
function getSexLabel(sex) {
  const sexLower = sex.toLowerCase();
  switch (sexLower) {
    case 'm':
    case 'male':
      return 'Masculino';
    case 'f':
    case 'female':
      return 'Femenino';
    default:
      return 'Otro';
  }
}

const ProjectionPanel = () => {
  const {
    planData,
    healthMetrics,
    caloricIntake: contextCaloricIntake,
    useCreatine: contextUseCreatine,
  } = usePlan();

  const { theme } = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  // ================================
  // 1. Lectura de datos
  // ================================
  const effectiveCaloricIntake = planData?.caloricIntake ?? contextCaloricIntake ?? 'recomp';
  const effectiveUseCreatine = planData?.useCreatine ?? contextUseCreatine ?? false;
  const { start, end } = planData || {};

  const {
    weight = 0,
    bodyFat, // Permitir que bodyFat sea undefined o null
    height = 0,
    age = 25,
    sex = 'M',
    activityFactor = 1.2,
  } = healthMetrics || {};

  // Determinar si bodyFat está disponible y es válido
  const isBodyFatAvailable = bodyFat !== undefined && bodyFat !== null && bodyFat > 0;

  // ================================
  // 2. Cálculo de días totales
  // ================================
  const totalDays = (() => {
    if (!start || !end) return 0;
    const startDate = new Date(start);
    const endDate = new Date(end);
    if (isNaN(startDate) || isNaN(endDate)) return 0;
    return differenceInDays(endDate, startDate);
  })();

  // ================================
  // 3. Cálculo del BMR (Mifflin-St Jeor)
  // ================================
  const bmr = (() => {
    if (weight <= 0 || height <= 0 || age <= 0) return 0;
    const base = 10 * weight + 6.25 * height - 5 * age;
    return sex.toLowerCase() === 'f' || sex.toLowerCase() === 'female' ? base - 161 : base + 5;
  })();

  // ================================
  // 4. Cálculo del TDEE (gasto total)
  // ================================
  const tdee = bmr * activityFactor;

  // ================================
  // 5. Ajuste calórico según objetivo
  // ================================
  const dailyCalorieDiff = (() => {
    if (!effectiveCaloricIntake) return 0;
    const intakeLower = effectiveCaloricIntake.toLowerCase();
    if (intakeLower.includes('def')) return -500;
    if (intakeLower.includes('vol')) return 500;
    // Por defecto: Recomp
    return 0;
  })();

  // ================================
  // 6. Calorías diarias recomendadas
  // ================================
  const dailyRecommendedCalories = (tdee ?? 0) + (dailyCalorieDiff ?? 0);

  // ================================
  // 7. Proyección de cambio de peso
  // ================================
  const totalCalorieDiff = dailyRecommendedCalories - tdee;
  const totalWeightChange = totalDays > 0 ? (totalCalorieDiff * totalDays) / 7700 : 0;

  // ================================
  // 8. Masa grasa y masa muscular inicial
  // ================================
  let initialFatMass = 0;
  let initialMuscleMass = weight;
  if (isBodyFatAvailable) {
    initialFatMass = (bodyFat / 100) * weight;
    initialMuscleMass = weight - initialFatMass;
  }

  // ================================
  // 9. Proyección final (ajustada con Factor de Actividad)
  // ================================
  const baseActivity = 1.2;
  const activityMultiplier = activityFactor / baseActivity;

  let finalFatMass = 0;
  let finalMuscleMass = weight;
  if (isBodyFatAvailable) {
    let fatChange = 0;
    let muscleChange = 0;
    const intakeLower = (effectiveCaloricIntake || '').toLowerCase();

    if (intakeLower.includes('def')) {
      // DEFINICIÓN
      const weightLoss = Math.min(0, totalWeightChange);
      fatChange = weightLoss * 0.8;
      muscleChange = weightLoss * 0.2;

      if (effectiveUseCreatine) {
        // Con creatina, se reduce a la mitad la pérdida de músculo
        muscleChange *= 0.5;
      }

      // Con más actividad, se pierde menos músculo
      muscleChange *= 1 / activityMultiplier;
    } else if (intakeLower.includes('vol')) {
      // VOLUMEN
      const weightGain = Math.max(0, totalWeightChange);
      fatChange = weightGain * 0.3;
      muscleChange = weightGain * 0.7;

      if (effectiveUseCreatine) {
        // Creatina: +10% de ganancia muscular
        muscleChange *= 1.1;
      }

      // Con más actividad, se gana más músculo
      muscleChange *= activityMultiplier;
    } else {
      // RECOMPOSICIÓN
      /*
        Ajuste realizado: Ahora las variaciones en masa grasa y muscular dependen de totalDays
        y del factor de actividad. Las tasas diarias se ajustan según el nivel de actividad.
      */

      // Definir tasas base diarias
      const baseDailyFatChangeRate = -0.03 / 100; // -0.03% por día
      const baseDailyMuscleChangeRate = 0.02 / 100; // +0.02% por día

      // Ajustar tasas según el factor de actividad
      const adjustedDailyFatChangeRate = baseDailyFatChangeRate * activityMultiplier;
      const adjustedDailyMuscleChangeRate = baseDailyMuscleChangeRate * activityMultiplier;

      // Calcular cambios totales basados en los días
      fatChange = initialFatMass * adjustedDailyFatChangeRate * totalDays;
      muscleChange = initialMuscleMass * adjustedDailyMuscleChangeRate * totalDays;

      if (effectiveUseCreatine) {
        // Creatina: +20% de esa ganancia
        muscleChange *= 1.2;
      }

      // Asegurar que los cambios no sean excesivos
      fatChange = Math.max(fatChange, -initialFatMass); // No puede perder más grasa de la que tiene
      muscleChange = Math.min(muscleChange, initialMuscleMass * 0.1); // Limitar ganancia muscular a un 10% inicial
    }

    finalFatMass = initialFatMass + fatChange;
    finalMuscleMass = initialMuscleMass + muscleChange;
    finalFatMass = Math.max(finalFatMass, 0);
    finalMuscleMass = Math.max(finalMuscleMass, 0);
  } else {
    // Proyección simplificada sin datos de grasa corporal
    finalMuscleMass = weight + (totalWeightChange * 0.2); // Asumiendo un 20% de ganancia/perdida muscular
  }

  // ================================
  // 10. Peso y % grasa final
  // ================================
  const finalWeight = finalFatMass + finalMuscleMass;
  const finalBodyFatPercentage = isBodyFatAvailable
    ? finalWeight > 0
      ? (finalFatMass / finalWeight) * 100
      : 0
    : null; // No calcular si no está disponible

  // ================================
  // 11. BMI Inicial y Final
  // ================================
  const heightM = height > 0 ? height / 100 : 1;
  const initialBmi = heightM > 0 ? weight / (heightM * heightM) : 0;
  const finalBmi = heightM > 0 ? finalWeight / (heightM * heightM) : 0;

  // ================================
  // 12. Recomendación de proteína
  // ================================
  const dailyProteinRecommended = weight * 1.8;

  // ================================
  // 13. Cálculo de calorías a comer hoy y calorías a gastar hoy
  //     Para cumplir con los requerimientos del plan
  // ================================
  // Calorías a comer hoy (sin clamp adicional, pero podría hacerse)
  const mustEatToday = dailyRecommendedCalories ?? 0;

  // Calorías a gastar hoy (no incluye BMR, se enfoca en la actividad y el plan)
  // Aseguramos que no sea negativo.
  const mustBurnToday = Math.max((dailyRecommendedCalories ?? 0) - (bmr ?? 0), 0);

  const togglePanel = () => setIsOpen((prev) => !prev);

  // Fallback si no hay métricas o datos esenciales (excepto bodyFat)
  if (
    !healthMetrics ||
    weight === null ||
    height === null ||
    age === null ||
    sex === null ||
    activityFactor === null ||
    !planData
  ) {
    return (
      <div
        className={
          `p-4 rounded shadow ${
            theme === 'dark' ? 'bg-gray-800 text-gray-200' : 'bg-gray-50 text-gray-900'
          }`
        }
      >
        <h2 className="text-lg font-semibold mb-2">Proyección</h2>
        <p className="text-sm">
          Crea un plan y guarda tus datos personales para ver tu proyección.
        </p>
        {!isBodyFatAvailable && (
          <p className="mt-2 text-sm text-yellow-500">
            Proyección más precisa si proporcionas tu porcentaje de grasa corporal en el formulario.
          </p>
        )}
      </div>
    );
  }

  // Estilos
  const containerClasses = `
    w-full p-4 rounded-md shadow-lg 
    transition-colors duration-300 
    ${theme === 'dark' ? 'bg-gray-800 text-gray-200' : 'bg-white text-gray-800'}
  `;
  const buttonClasses = `
    flex items-center gap-2
    px-4 py-2 rounded-md font-semibold
    transition-all duration-300 transform
    hover:scale-105 text-white hover:shadow-lg
    focus:outline-none focus:ring-2 focus:ring-indigo-400
    ${
      isOpen
        ? theme === 'dark'
          ? 'bg-gradient-to-r from-indigo-600 to-indigo-500 hover:to-indigo-600'
          : 'bg-indigo-700 hover:bg-indigo-600'
        : theme === 'dark'
        ? 'bg-gradient-to-r from-indigo-500 to-indigo-400 hover:to-indigo-500'
        : 'bg-indigo-600 hover:bg-indigo-700'
    }
  `;
  const expandClasses = `
    overflow-hidden transition-all duration-500 ease-in-out
    ${isOpen ? 'max-h-[2000px] mt-4 opacity-100' : 'max-h-0 mt-0 opacity-0'}
  `;
  const headingBorderColor = theme === 'dark' ? 'border-gray-700' : 'border-gray-300';
  const subTextColor = theme === 'dark' ? 'text-gray-300' : 'text-gray-700';
  const dividerColor = theme === 'dark' ? 'border-gray-700' : 'border-gray-300';
  const cardClasses = `
    bg-gray-100 dark:bg-gray-800 p-3 rounded shadow-inner
    ${theme === 'dark' ? 'bg-gray-700 text-gray-100' : 'bg-gray-100 text-gray-800'}
  `;

  // Calculamos diferencias para el preview
  const weightDiff = finalWeight - weight;
  const bfDiff = isBodyFatAvailable ? finalBodyFatPercentage - bodyFat : null;
  const muscleDiff = finalMuscleMass - (isBodyFatAvailable ? (weight - (bodyFat / 100) * weight) : weight);
  const bmiDiff = finalBmi - initialBmi;

  return (
    <div className="p-4 w-full relative transition-colors duration-300 ease-in-out">
      {/* 
        BOTÓN + Resumen de Proyección 
      */}
      <div className="flex items-center gap-4 mb-4 flex-wrap">
        {/* BOTÓN: Ver/Ocultar Proyección */}
        <button
          className={buttonClasses}
          onClick={togglePanel}
          aria-expanded={isOpen}
          aria-label={isOpen ? 'Ocultar proyección' : 'Ver proyección'}
        >
          <span className="text-white dark:text-gray-200">
            {isOpen ? 'Ocultar Proyección' : 'Ver Proyección'}
          </span>
          {isOpen ? (
            <MdExpandLess className="text-white dark:text-gray-200" />
          ) : (
            <MdExpandMore className="text-white dark:text-gray-200" />
          )}
        </button>

        {/* PREVISUALIZACIÓN (solo cuando el panel NO está abierto) */}
        {!isOpen && (
          <div className="flex items-center gap-4 transition-all duration-500 flex-wrap">
            {formatDiff(weightDiff, 'Peso total (kg)')}
            {isBodyFatAvailable && formatDiff(bfDiff, '% Grasa', true)}
            {formatDiff(muscleDiff, 'Músculo (kg)')}
            {formatDiff(bmiDiff, 'BMI')}

            {/* Calorías a Comer Hoy */}
            <div
              className={
                'flex items-center gap-1 text-sm cursor-pointer p-2 rounded-md ' +
                'hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-300'
              }
            >
              <MdFastfood className="inline text-indigo-500 dark:text-indigo-300" />
              <span className="text-gray-700 dark:text-gray-200">
                {Number(mustEatToday).toFixed(0)} Calorías a Comer por día
              </span>
            </div>

            {/* Calorías a Gastar Hoy */}
            <div
              className={
                'flex items-center gap-1 text-sm cursor-pointer p-2 rounded-md ' +
                'hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-300'
              }
            >
              <MdLocalFireDepartment className="inline text-indigo-500 dark:text-indigo-300" />
              <span className="text-gray-700 dark:text-gray-200">
                {Number(mustBurnToday).toFixed(0)} Calorías a Gastar por día
              </span>
            </div>

            {/* Días Totales */}
            <div
              className={
                'flex items-center gap-1 text-sm cursor-pointer p-2 rounded-md ' +
                'hover:bg-indigo-50 dark:hover:bg-gray-700 transition-colors duration-300'
              }
            >
              <MdDateRange className="text-indigo-500 dark:text-indigo-300" />
              <span className="text-gray-700 dark:text-gray-200">{totalDays} días</span>
            </div>
          </div>
        )}
      </div>

      {/* CONTENEDOR EXPANDIBLE */}
      <div className={expandClasses}>
        <div className={containerClasses}>
          {/* TÍTULO */}
          <div className={`mb-4 pb-2 border-b ${headingBorderColor}`}>
            <h2 className="text-lg font-semibold">Proyección</h2>
            <p className={`mt-1 text-sm ${subTextColor}`}>
              Estimación aproximada de tu progreso.
            </p>
          </div>

          {/* Fechas y días (si existen) */}
          {start && end ? (
            <div className="mb-4 flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2">
              <div>
                <p className="font-semibold">Inicio del plan:</p>
                <p>{new Date(start).toLocaleDateString()}</p>
              </div>
              <div>
                <p className="font-semibold">Fin del plan:</p>
                <p>{new Date(end).toLocaleDateString()}</p>
              </div>
              <div>
                <p className="font-semibold">Días Totales:</p>
                <p>{totalDays}</p>
              </div>
            </div>
          ) : (
            <div className="mb-4">
              <p className="text-sm italic">
                Aún no has definido las fechas de tu plan.
              </p>
            </div>
          )}

          <hr className={`my-2 ${dividerColor}`} />

          {/* Estimaciones */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* Estado Inicial */}
            <div className={cardClasses}>
              <h3 className="font-semibold mb-2">Estado Inicial</h3>
              <ul className="list-disc ml-5 space-y-1 text-sm">
                <li>Peso (total): {Number(weight).toFixed(1)} kg</li>
                {isBodyFatAvailable && <li>% Grasa: {Number(bodyFat).toFixed(1)} %</li>}
                {isBodyFatAvailable && (
                  <li>Masa Grasa: {Number(initialFatMass).toFixed(1)} kg</li>
                )}
                <li>Masa Muscular: {Number(initialMuscleMass).toFixed(1)} kg</li>
                <li>BMI: {Number(initialBmi).toFixed(1)}</li>
              </ul>
            </div>

            {/* Proyección Final */}
            <div className={cardClasses}>
              <h3 className="font-semibold mb-2">
                Proyección al finalizar ({totalDays} días)
              </h3>
              <ul className="list-disc ml-5 space-y-1 text-sm">
                <li>
                  Peso proyectado (total):{' '}
                  <span className="font-semibold">{Number(finalWeight).toFixed(1)} kg</span>
                </li>
                {isBodyFatAvailable && (
                  <li>
                    % Grasa proyectado:{' '}
                    <span className="font-semibold">
                      {Number(finalBodyFatPercentage).toFixed(1)} %
                    </span>
                  </li>
                )}
                {isBodyFatAvailable && (
                  <li>
                    Masa Grasa proyectada:{' '}
                    <span className="font-semibold">{Number(finalFatMass).toFixed(1)} kg</span>
                  </li>
                )}
                <li>
                  Masa Muscular proyectada:{' '}
                  <span className="font-semibold">{Number(finalMuscleMass).toFixed(1)} kg</span>
                </li>
                <li>
                  BMI proyectado:{' '}
                  <span className="font-semibold">{Number(finalBmi).toFixed(1)}</span>
                </li>
              </ul>
            </div>
          </div>

          {/* Mostrar mensaje de precisión si bodyFat no está disponible */}
          {!isBodyFatAvailable && (
            <div className="mt-4 p-3 bg-yellow-100 dark:bg-yellow-700 rounded">
              <p className="text-sm text-yellow-800 dark:text-yellow-200">
                Para una proyección más precisa, por favor proporciona tu porcentaje de grasa
                corporal en el formulario.
              </p>
            </div>
          )}

          <hr className={`my-4 ${dividerColor}`} />

          {/* Datos adicionales */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 text-sm">
            <div>
              <p>
                <span className="font-semibold">Sexo:</span> {getSexLabel(sex)}
              </p>
              <p>
                <span className="font-semibold">Edad:</span> {age} años
              </p>
              <p>
                <span className="font-semibold">Altura:</span> {height} cm
              </p>
            </div>
            <div>
              <p>
                <span className="font-semibold">Factor de Actividad:</span> {activityFactor}
              </p>
              <p>
                <span className="font-semibold">Objetivo Calórico:</span>{' '}
                {getCaloricIntakeLabel(effectiveCaloricIntake)}
              </p>
              <p>
                <span className="font-semibold">TDEE estimado:</span>{' '}
                {Number(tdee).toFixed(0)} kcal/día
              </p>
              <p>
                <span className="font-semibold">Calorías Diarias Recomendadas:</span>{' '}
                {Number(dailyRecommendedCalories).toFixed(0)} kcal
              </p>
              <p>
                <span className="font-semibold">Proteína Recomendada:</span>{' '}
                {Number(dailyProteinRecommended).toFixed(0)} g/día
              </p>
              <p>
                <span className="font-semibold">Uso de Creatina:</span>{' '}
                {effectiveUseCreatine ? 'Sí' : 'No'}
              </p>
            </div>
          </div>

          <hr className={`my-4 ${dividerColor}`} />

          <p className={`text-xs leading-5 ${subTextColor}`}>
            <strong>Nota:</strong> Estas cifras son estimaciones generales y <strong>no</strong>{' '}
            sustituyen el asesoramiento profesional. Se basan en la fórmula Mifflin-St Jeor,
            supuestos simplificados de distribución de peso y aproximaciones de ganancia/pérdida
            de músculo y grasa. El factor de actividad influye en las tasas de ganancia/perdida
            muscular y grasa. Más actividad puede resultar en mayor ganancia muscular y mayor
            pérdida de grasa.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectionPanel;
