// src/Components/Sidebar/formats/HealthMetricsForm.jsx

import React, { useState } from 'react';
import { useTheme } from '../../ThemeContext';
import useHealthMetrics from './useHealthMetrics';

// Componentes opcionales para visualización de medidas
import MeasurementOfNeck from './MeasurementOfNeck';
import MeasurementOfWaist from './MeasurementOfWaist';
import MeasurementOfHips from './MeasurementOfHips';

const HealthMetricsForm = () => {
  const {
    formData,
    handleSubmit,
    handleDelete,
    handleInputChange,
    handleCheckboxChange,
    handleActivityFactorChange,
    handleDurationChange,
    errorMessages,
    isSaved,
    isPlanActive,
    showAlert,
    activityLevels,
    generateSummary,
    resultsRef,
    visibleSVG,
    toggleSVG,
    svgContainerRef,
    error, // Manejo de errores de API
  } = useHealthMetrics();

  const { theme } = useTheme();

  // Saber si es nuevo plan o uno ya existente
  const isNewPlan = !formData._planId; // true => no hay plan en BD

  // Opciones de duración (en días)
  const durations = [
    { label: '1 semana', value: 7 },
    { label: '2 semanas', value: 14 },
    { label: '1 mes', value: 30 },
    { label: '2 meses', value: 60 },
    { label: '3 meses', value: 90 },
  ];

  // Estado para controlar la visibilidad de las secciones
  const [isPlanOpen, setIsPlanOpen] = useState(false); // Cambiado a false
  const [isPersonalDataOpen, setIsPersonalDataOpen] = useState(false); // Cambiado a false
  const [isStateOpen, setIsStateOpen] = useState(false); // Cambiado a false

  // Funciones para togglear las secciones
  const togglePlanSection = () => setIsPlanOpen((prev) => !prev);
  const togglePersonalDataSection = () => setIsPersonalDataOpen((prev) => !prev);
  const toggleStateSection = () => setIsStateOpen((prev) => !prev);

  return (
    <div
      className={`w-full max-w-md mx-auto p-6 rounded-lg shadow-md ${
        theme === 'light' ? 'bg-white text-gray-700' : 'bg-gray-800 text-gray-300'
      } transition-colors duration-300`}
    >
      <form onSubmit={handleSubmit} className="space-y-6">
        {/*=== SECCIÓN: DATOS DEL PLAN ===*/}
        <div className="mb-4">
          <button
            type="button"
            onClick={togglePlanSection}
            className="w-full flex justify-between items-center p-3 bg-blue-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
            aria-expanded={isPlanOpen}
            aria-controls="plan-section"
          >
            <span className="font-medium">Mi Plan</span>
            <span>{isPlanOpen ? '−' : '+'}</span>
          </button>
          {isPlanOpen && (
            <div
              id="plan-section"
              className={`mt-2 p-4 rounded-md ${
                theme === 'light' ? 'bg-gray-50' : 'bg-gray-700'
              }`}
            >
              <h2 className="text-md font-semibold mb-3">Datos del Plan</h2>
              {/* Objetivo de Plan */}
              <div className="flex flex-col mb-4">
                <label htmlFor="caloricIntake" className="mb-1 font-semibold text-sm">
                  Objetivo de Plan:
                </label>
                <select
                  name="caloricIntake"
                  id="caloricIntake"
                  value={formData.caloricIntake}
                  onChange={handleInputChange}
                  className={`p-2 border rounded-md focus:outline-none focus:ring-2 ${
                    theme === 'light'
                      ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                      : 'bg-gray-900 border-gray-600 focus:ring-green-200 text-gray-100 focus:bg-gray-700'
                  }`}
                >
                  <option value="recomp">Recomposición</option>
                  <option value="volum">Volumen</option>
                  <option value="defin">Definición</option>
                </select>
              </div>

              {/* Uso de Creatina */}
              <div className="flex items-center space-x-2 mb-4">
                <input
                  type="checkbox"
                  name="useCreatine"
                  id="useCreatine"
                  checked={formData.useCreatine}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="useCreatine" className="font-semibold text-sm">
                  ¿Usar Creatina en el plan?
                </label>
              </div>

              {/* Fecha de Inicio y Fecha Fin (o Duración) */}
              {isNewPlan ? (
                <div className="flex flex-col">
                  <label className="mb-1 font-semibold text-sm">
                    Fecha de inicio del plan:
                  </label>
                  <p className="text-sm mb-2">
                    {formData.startDate} (Hoy) {/* Solo lectura */}
                  </p>

                  <label htmlFor="planDuration" className="mb-1 font-semibold text-sm">
                    Duración del Plan:
                  </label>
                  <select
                    name="planDuration"
                    id="planDuration"
                    value={formData.planDuration}
                    onChange={handleDurationChange}
                    className={`p-2 border rounded-md focus:outline-none focus:ring-2 ${
                      theme === 'light'
                        ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                        : 'bg-gray-900 border-gray-600 focus:ring-green-200 text-gray-100 focus:bg-gray-700'
                    }`}
                  >
                    {durations.map((d) => (
                      <option key={d.value} value={d.value}>
                        {d.label}
                      </option>
                    ))}
                  </select>

                  <p className="text-xs mt-2">
                    Se calculará la fecha de fin automáticamente al guardar.
                  </p>
                </div>
              ) : (
                <>
                  <div className="flex flex-col mb-4">
                    <label htmlFor="startDate" className="mb-1 font-semibold text-sm">
                      Fecha de inicio del plan:
                    </label>
                    <input
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={formData.startDate}
                      onChange={handleInputChange}
                      disabled // Bloqueado si el plan ya existe
                      className={`p-2 border rounded-md focus:outline-none focus:ring-2 ${
                        theme === 'light'
                          ? 'bg-gray-200 border-gray-300 text-gray-500'
                          : 'bg-gray-700 border-gray-600 text-gray-400'
                      }`}
                    />
                  </div>

                  <div className="flex flex-col">
                    <label htmlFor="endDate" className="mb-1 font-semibold text-sm">
                      Fecha de fin del plan:
                    </label>
                    <input
                      type="date"
                      name="endDate"
                      id="endDate"
                      value={formData.endDate}
                      onChange={handleInputChange}
                      className={`p-2 border rounded-md focus:outline-none focus:ring-2 ${
                        theme === 'light'
                          ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                          : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                      }`}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        {/*=== SECCIÓN: DATOS PERSONALES Y MÉTRICAS BÁSICAS ===*/}
        <div className="mb-4">
          <button
            type="button"
            onClick={togglePersonalDataSection}
            className="w-full flex justify-between items-center p-3 bg-blue-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
            aria-expanded={isPersonalDataOpen}
            aria-controls="personal-data-section"
          >
            <span className="font-medium">Mis Datos</span>
            <span>{isPersonalDataOpen ? '−' : '+'}</span>
          </button>
          {isPersonalDataOpen && (
            <div
              id="personal-data-section"
              className={`mt-2 p-4 rounded-md ${
                theme === 'light' ? 'bg-gray-50' : 'bg-gray-700'
              }`}
            >
              <h2 className="text-md font-semibold mb-3">Datos Personales</h2>

              {/* Fecha de Nacimiento */}
              <div className="flex flex-col mb-4">
                <label htmlFor="dob" className="mb-1 font-semibold text-sm">
                  Fecha de Nacimiento:
                </label>
                <input
                  type="date"
                  name="dob"
                  id="dob"
                  value={formData.dob}
                  onChange={handleInputChange}
                  required
                  className={`p-2 border rounded-md focus:outline-none focus:ring-2 ${
                    theme === 'light'
                      ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                      : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                  }`}
                />
              </div>

              {/* Sexo */}
              <div className="flex flex-col mb-4">
                <label htmlFor="sex" className="mb-1 font-semibold text-sm">
                  Sexo:
                </label>
                <select
                  name="sex"
                  id="sex"
                  value={formData.sex}
                  onChange={handleInputChange}
                  required
                  className={`p-2 border rounded-md focus:outline-none focus:ring-2 ${
                    theme === 'light'
                      ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                      : 'bg-gray-900 border-gray-600 focus:ring-green-200 text-gray-100 focus:bg-gray-700'
                  }`}
                >
                  <option value="male">Hombre</option>
                  <option value="female">Mujer</option>
                </select>
              </div>

              {/* Peso */}
              <div className="flex flex-col mb-4">
                <label htmlFor="weight" className="mb-1 font-semibold text-sm">
                  Peso (kg):
                </label>
                <input
                  type="number"
                  name="weight"
                  id="weight"
                  value={formData.weight}
                  onChange={handleInputChange}
                  required
                  min="0"
                  step="0.1"
                  className={`p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
                    theme === 'light'
                      ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                      : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                  }`}
                />
              </div>

              {/* Estatura */}
              <div className="flex flex-col mb-4">
                <label htmlFor="height" className="mb-1 font-semibold text-sm">
                  Estatura (cm):
                </label>
                <input
                  type="number"
                  name="height"
                  id="height"
                  value={formData.height}
                  onChange={handleInputChange}
                  required
                  min="0"
                  step="0.1"
                  className={`p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
                    theme === 'light'
                      ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                      : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                  }`}
                />
              </div>

              {/* Factor de Actividad */}
              <div className="flex flex-col mb-4">
                <label htmlFor="activityFactor" className="mb-1 font-semibold text-sm">
                  Factor de Actividad Física:
                </label>
                <input
                  type="range"
                  name="activityFactor"
                  id="activityFactor"
                  min="1.2"
                  max="1.9"
                  step="0.175"
                  value={formData.activityFactor}
                  onChange={handleActivityFactorChange}
                  required
                  className="w-full"
                />
                <span className="mt-1 text-sm">
                  {
                    activityLevels.find((level) => level.value === parseFloat(formData.activityFactor))
                      ?.label || 'Selecciona un nivel de actividad'
                  }
                </span>
              </div>

              {/*=== SECCIÓN: MEDIDAS OPCIONALES (Siempre Visible) ===*/}
              <div className="mb-4">
                <h3 className="text-md font-semibold mb-1">Medidas Opcionales</h3>
                <p className="text-xs mb-4">
                  El porcentaje de grasa corporal puede mejorar la precisión de tus proyecciones.
                </p>

                {/* Cuello */}
                <div className="flex flex-col relative mb-4">
                  <label htmlFor="neck" className="mb-1 font-semibold text-sm">
                    Perímetro del Cuello (cm): <span className="text-gray-500">(Opcional)</span>
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      name="neck"
                      id="neck"
                      value={formData.neck}
                      onChange={handleInputChange}
                      min="0"
                      step="0.1"
                      className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
                        theme === 'light'
                          ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                          : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => toggleSVG('neck')}
                      className="absolute right-2 top-2 w-5 h-5 rounded-full bg-blue-500 text-white text-xs flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-blue-300"
                      aria-label="Cómo medir el perímetro del cuello"
                    >
                      ?
                    </button>
                  </div>
                  {visibleSVG === 'neck' && (
                    <div
                      className="mt-2"
                      ref={svgContainerRef}
                      tabIndex="-1"
                    >
                      <MeasurementOfNeck />
                    </div>
                  )}
                </div>

                {/* Cintura */}
                <div className="flex flex-col relative mb-4">
                  <label htmlFor="waist" className="mb-1 font-semibold text-sm">
                    Perímetro de la Cintura (cm): <span className="text-gray-500">(Opcional)</span>
                  </label>
                  <div className="relative">
                    <input
                      type="number"
                      name="waist"
                      id="waist"
                      value={formData.waist}
                      onChange={handleInputChange}
                      min="0"
                      step="0.1"
                      className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
                        theme === 'light'
                          ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                          : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => toggleSVG('waist')}
                      className="absolute right-2 top-2 w-5 h-5 rounded-full bg-blue-500 text-white text-xs flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-blue-300"
                      aria-label="Cómo medir el perímetro de la cintura"
                    >
                      ?
                    </button>
                  </div>
                  {visibleSVG === 'waist' && (
                    <div
                      className="mt-2"
                      ref={svgContainerRef}
                      tabIndex="-1"
                    >
                      <MeasurementOfWaist />
                    </div>
                  )}
                </div>

                {/* Cadera (solo para mujeres) */}
                {formData.sex === 'female' && (
                  <div className="flex flex-col relative">
                    <label htmlFor="hip" className="mb-1 font-semibold text-sm">
                      Perímetro de la Cadera (cm): <span className="text-gray-500">(Opcional)</span>
                    </label>
                    <div className="relative">
                      <input
                        type="number"
                        name="hip"
                        id="hip"
                        value={formData.hip}
                        onChange={handleInputChange}
                        min="0"
                        step="0.1"
                        className={`w-full p-2 border rounded-md focus:outline-none focus:ring-2 transition-colors duration-300 ${
                          theme === 'light'
                            ? 'bg-white border-gray-300 focus:ring-blue-500 text-gray-700'
                            : 'bg-gray-800 border-gray-600 focus:ring-green-400 text-gray-100 focus:bg-gray-700'
                        }`}
                      />
                      <button
                        type="button"
                        onClick={() => toggleSVG('hip')}
                        className="absolute right-2 top-2 w-5 h-5 rounded-full bg-blue-500 text-white text-xs flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-blue-300"
                        aria-label="Cómo medir el perímetro de la cadera"
                      >
                        ?
                      </button>
                    </div>
                    {visibleSVG === 'hip' && (
                      <div
                        className="mt-2"
                        ref={svgContainerRef}
                        tabIndex="-1"
                      >
                        <MeasurementOfHips />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {/*=== ERRORES DE VALIDACIÓN ===*/}
        {errorMessages.length > 0 && (
          <div className="mt-4 p-2 bg-red-200 text-red-700 rounded-md">
            {errorMessages.map((error, index) => (
              <p key={index} className="text-sm">
                {error}
              </p>
            ))}
          </div>
        )}

        {/*=== ERRORES DE API ===*/}
        {error && (
          <div className="mt-4 p-2 bg-red-300 text-red-800 rounded-md">
            {error}
          </div>
        )}

        {/*=== BOTONES ===*/}
        <div className="flex space-x-2 justify-center mt-6">
          <button
            type="submit"
            disabled={isSaved}
            className={`px-4 py-2 rounded-md font-semibold focus:outline-none focus:ring-2 transition-all duration-300 ${
              isSaved
                ? 'bg-gray-400 text-gray-200 cursor-not-allowed opacity-50'
                : theme === 'light'
                ? 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-300'
                : 'bg-blue-700 text-white hover:bg-blue-800 focus:ring-green-400'
            }`}
          >
            Guardar
          </button>

          <button
            type="button"
            onClick={handleDelete}
            disabled={isPlanActive}
            className={`px-4 py-2 rounded-md font-semibold focus:outline-none focus:ring-2 transition-all duration-300 ${
              isPlanActive
                ? 'bg-gray-400 text-gray-200 cursor-not-allowed opacity-50'
                : theme === 'light'
                ? 'bg-blue-500 text-white hover:bg-blue-600 focus:ring-blue-300'
                : 'bg-blue-700 text-white hover:bg-blue-800 focus:ring-blue-400'
            }`}
          >
            Eliminar
          </button>
        </div>

        {showAlert && (
          <div className="mt-4 p-2 bg-green-200 text-green-700 text-center rounded-md animate-fade-in">
            Datos actualizados.
          </div>
        )}
      </form>

      {/*=== SECCIÓN: ESTADO FÍSICO ===*/}
      <div className="mt-4">
        <button
          type="button"
          onClick={toggleStateSection}
          className="w-full flex justify-between items-center p-3 bg-blue-500 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300"
          aria-expanded={isStateOpen}
          aria-controls="state-section"
        >
          <span className="font-medium">Mi Estado</span>
          <span>{isStateOpen ? '−' : '+'}</span>
        </button>
        {isStateOpen && (
          <div
            id="state-section"
            className={`mt-2 p-4 rounded-md ${
              theme === 'light' ? 'bg-gray-50' : 'bg-gray-700'
            }`}
          >
            <div ref={resultsRef} className="text-center">
              <h2 className="text-lg font-semibold">Tus Métricas</h2>

              {formData.bmi && (
                <p className="mt-2">
                  <span className="font-medium">IMC:</span>{' '}
                  <span className="text-blue-500">{formData.bmi}</span>
                </p>
              )}

              {formData.age < 18 && formData.bmiPercentile && (
                <p className="mt-2">
                  <span className="font-medium">Percentil de IMC:</span>{' '}
                  <span className="text-blue-500">{formData.bmiPercentile}</span>
                </p>
              )}

              {formData.bodyFat && (
                <p className="mt-2">
                  <span className="font-medium">% de Grasa Corporal:</span>{' '}
                  <span className="text-blue-500">{formData.bodyFat}</span>
                </p>
              )}

              {formData.tdee && (
                <p className="mt-2">
                  <span className="font-medium">Gasto Calórico Total Diario (TDEE):</span>{' '}
                  <span className="text-blue-500">{formData.tdee}</span> calorías
                </p>
              )}

              {formData.bmr && (
                <p className="mt-2">
                  <span className="font-medium">Tasa Metabólica Basal (BMR):</span>{' '}
                  <span className="text-blue-500">{formData.bmr}</span> calorías
                </p>
              )}

              {/* Estado físico calculado */}
              {formData.fitnessLevel && (
                <p className="mt-2">
                  <span className="font-medium">Estado Físico (calculado):</span>{' '}
                  <span className="text-blue-500">{formData.fitnessLevel}</span>
                </p>
              )}
            </div>

            {/*=== Resumen del Estado Físico ===*/}
            {formData.bmi && (
              <div className="mt-8 text-left">
                <h2 className="text-lg font-semibold">Resumen de tu Estado Físico</h2>
                <p
                  className="mt-2"
                  dangerouslySetInnerHTML={{ __html: generateSummary(formData) }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HealthMetricsForm;
