import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import assistantIcon from './assistant.svg';
import { useTheme } from '../ThemeContext';
import ChatWindow from './ChatWindow';
import { AnimatePresence, motion, useMotionValue } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import { useAuth } from '../contexts/AuthContext';
import { useChat } from '../contexts/ChatContext';

const doesChatNameExist = (name, chats) => {
  return chats.some((chat) => chat.name === name);
};

const AssistantChat = ({ isOpen, toggleChat }) => {
  const { theme } = useTheme();
  const { user, loading, login } = useAuth();
  const {
    chats,
    addChat,
    updateChat,
    deleteChat,
    currentChatId,
    setCurrentChatId,
    isLoading,
    sendMessage,
  } = useChat();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [input, setInput] = useState('');
  const sidebarRef = useRef(null);
  const toggleButtonRef = useRef(null);
  const buttonRef = useRef(null);
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        setIsSidebarOpen(false);
      }
    };

    if (isSidebarOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSidebarOpen]);

  const generateUniqueChatName = () => {
    const baseName = 'Nueva Conversación';
    let newName = baseName;
    let counter = 1;

    while (doesChatNameExist(newName, chats)) {
      counter += 1;
      newName = `${baseName} ${counter}`;
    }

    return newName;
  };

  const handleNewChat = () => {
    const newName = generateUniqueChatName();
    const newChat = {
      id: uuidv4(),
      name: newName,
      messages: [],
    };
    addChat(newChat);
    setCurrentChatId(newChat.id);
    setIsSidebarOpen(true);
  };

  const handleSelectChat = (id) => {
    setCurrentChatId(id);
    setIsSidebarOpen(false);
  };

  const handleToggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const handleRenameChat = (id, newName) => {
    if (!newName || newName.trim() === '') {
      alert('El nombre del chat no puede estar vacío.');
      return;
    }

    if (chats.some((chat) => chat.name === newName && chat.id !== id)) {
      alert('Ya existe un chat con ese nombre. Por favor, elige otro nombre.');
      return;
    }

    const updatedChat = chats.find((chat) => chat.id === id);
    if (updatedChat) {
      updateChat(id, { ...updatedChat, name: newName.trim() });
    }
  };

  const handleDeleteChat = (id) => {
    deleteChat(id);
    if (id === currentChatId) {
      const remainingChats = chats.filter((chat) => chat.id !== id);
      if (remainingChats.length > 0) {
        setCurrentChatId(remainingChats[0].id);
      } else {
        setCurrentChatId(null);
      }
    }
  };

  const currentChat = chats.find((chat) => chat.id === currentChatId);

  const handleSend = () => {
    if (input.trim() === '') return;
    sendMessage(input);
    setInput('');
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (isSidebarOpen) {
        setIsSidebarOpen(false);
      }
    },
    onSwipedRight: () => {
      if (!isSidebarOpen) {
        setIsSidebarOpen(true);
      }
    },
    delta: 50,
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  if (loading) {
    return null;
  }

  return (
    <div>
      <motion.button
        ref={buttonRef}
        onClick={toggleChat}
        className="fixed bottom-4 right-4 w-56 h-56 md:w-72 md:h-72 lg:w-96 lg:h-96 rounded-full hover:bg-black hover:bg-opacity-20 flex items-center justify-center focus:outline-none z-40 transition-colors duration-200"
        aria-label={isOpen ? 'Cerrar chat' : 'Abrir chat'}
        drag
        dragMomentum={false}
        dragElastic={0}
        style={{ x, y }}
        onDragEnd={() => {
          const buttonRect = buttonRef.current.getBoundingClientRect();
          const buttonWidth = buttonRect.width;
          const buttonHeight = buttonRect.height;
          const screenWidth = window.innerWidth;
          const screenHeight = window.innerHeight;

          // Calcular posición inicial desde la derecha
          const initialLeft = screenWidth - buttonWidth - 16;
          const initialTop = screenHeight - buttonHeight - 16;

          const currentX = x.get();
          const currentY = y.get();

          const buttonLeft = initialLeft + currentX;
          const buttonTop = initialTop + currentY;

          const distanceToLeft = buttonLeft;
          const distanceToRight = screenWidth - (buttonLeft + buttonWidth);
          const distanceToTop = buttonTop;
          const distanceToBottom = screenHeight - (buttonTop + buttonHeight);

          const distances = [
            { edge: 'left', distance: distanceToLeft },
            { edge: 'right', distance: distanceToRight },
            { edge: 'top', distance: distanceToTop },
            { edge: 'bottom', distance: distanceToBottom },
          ];

          const nearestEdge = distances.reduce((prev, curr) => {
            return prev.distance < curr.distance ? prev : curr;
          });

          if (nearestEdge.edge === 'left') {
            x.set(-initialLeft);
          } else if (nearestEdge.edge === 'right') {
            x.set(screenWidth - buttonWidth - 16 - initialLeft);
          } else if (nearestEdge.edge === 'top') {
            y.set(16 - initialTop);
          } else if (nearestEdge.edge === 'bottom') {
            y.set(0);
          }
        }}
      >
        <img
          src={assistantIcon}
          alt="Assistant"
          className="w-full h-full object-contain"
        />
      </motion.button>

      <AnimatePresence>
        {isOpen &&
          (user ? (
            <ChatWindow
              key="chat-window"
              handlers={handlers}
              theme={theme}
              toggleChat={toggleChat}
              isSidebarOpen={isSidebarOpen}
              handleToggleSidebar={handleToggleSidebar}
              sidebarRef={sidebarRef}
              toggleButtonRef={toggleButtonRef}
              chats={chats}
              currentChatId={currentChatId}
              handleSelectChat={handleSelectChat}
              handleNewChat={handleNewChat}
              handleRenameChat={handleRenameChat}
              handleDeleteChat={handleDeleteChat}
              currentChat={currentChat}
              input={input}
              setInput={setInput}
              handleSend={handleSend}
              isLoading={isLoading}
            />
          ) : (
            <div
              key="login-message"
              className={`fixed bottom-20 right-4 sm:right-auto sm:bottom-auto sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 z-50`}
            >
              <p className="text-center text-gray-800 dark:text-gray-200 mb-4">
                Por favor, inicia sesión para utilizar el chat.
              </p>
              <button
                onClick={login}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 focus:outline-none"
              >
                Iniciar Sesión
              </button>
            </div>
          ))}
      </AnimatePresence>
    </div>
  );
};

export default AssistantChat;