// src/Components/Sidebar/formats/useHealthMetrics.js

import { useEffect, useState, useRef } from 'react';
import useDataManagement from './useDataManagement';
import useFormValidation from './useFormValidation';
import {
  calculateAge,
  calculateBMI,
  calculateBMIPercentile,
  calculateBodyFat,
  calculateTDEE,
  generateSummary,
  calculateFitnessLevel, // <-- IMPORTAMOS LA FUNCIÓN CORRECTA
} from './healthCalculations';

const useHealthMetrics = () => {
  // Estado inicial
  const initialData = {
    // Métricas de salud
    dob: '',
    sex: 'male',
    weight: '',
    height: '',
    activityFactor: 1.2,
    neck: '',
    waist: '',
    hip: '',
    bmi: null,
    bodyFat: null,
    tdee: null,
    bmr: null,
    age: null,
    bmiPercentile: null,
    fitnessLevel: '', // Se calculará automáticamente

    // Plan
    _planId: null, // identificador del plan
    startDate: '', // se seteará por defecto con la fecha de hoy si no hay plan
    endDate: '',
    caloricIntake: 'recomp',
    useCreatine: false,

    // Duración del plan (solo si no hay plan). En días.
    planDuration: 7, // por defecto 1 semana
  };

  const {
    formData,
    setFormData,
    isSaved,
    setIsSaved,
    handleDelete,
    saveFormData,
    user,
    error, // Añadido para manejar errores
  } = useDataManagement(initialData);

  const { validateForm } = useFormValidation();

  const [showAlert, setShowAlert] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [visibleSVG, setVisibleSVG] = useState(null);
  const svgContainerRef = useRef(null);
  const timeoutId = useRef(null);
  const [isPlanActive, setIsPlanActive] = useState(!!formData._planId);
  const resultsRef = useRef(null);

  // Actualizar isPlanActive cuando cambia _planId
  useEffect(() => {
    setIsPlanActive(!!formData._planId);
  }, [formData._planId]);

  // Si NO hay un plan (_planId === null), forzamos la fecha de inicio a hoy (solo si está vacía)
  useEffect(() => {
    if (!formData._planId && !formData.startDate) {
      const today = new Date().toISOString().split('T')[0];
      setFormData((prev) => ({ ...prev, startDate: today }));
    }
  }, [formData._planId, formData.startDate, setFormData]);

  // Cuando el usuario hace Submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    const { errors, isValid, age } = validateForm(formData);
    if (!isValid) {
      setErrorMessages(errors);
      return;
    } else {
      setErrorMessages([]);
    }

    // Calcular la edad por si no la tenemos
    const calculatedAge = age || calculateAge(formData.dob);

    // Calcular métricas de salud
    const bmiValue = calculateBMI(formData.weight, formData.height);
    const bmiPercentile = calculateBMIPercentile(
      calculatedAge,
      formData.sex,
      bmiValue
    );
    const bodyFatValue = calculateBodyFat(
      formData.sex,
      formData.weight,
      formData.height,
      calculatedAge,
      formData.neck,
      formData.waist,
      formData.hip
    );
    const { bmr, tdee } = calculateTDEE(
      formData.sex,
      formData.weight,
      formData.height,
      calculatedAge,
      formData.activityFactor
    );

    // Calcular estado físico (fitnessLevel)
    const fitnessLevel = calculateFitnessLevel(
      formData.sex,
      calculatedAge,
      parseFloat(bmiValue),
      parseFloat(bodyFatValue)
    );

    // Si NO existe plan (_planId === null), calculamos fecha de fin
    // a partir de la duración seleccionada
    let endDate = formData.endDate;
    if (!formData._planId) {
      // Convertimos la fecha de inicio a obj Date
      const start = new Date(formData.startDate);
      // Sumar la duración en días
      const msToAdd = formData.planDuration * 24 * 60 * 60 * 1000;
      const computedEnd = new Date(start.getTime() + msToAdd);
      endDate = computedEnd.toISOString().split('T')[0];
    }

    // Actualizar formData con resultados
    const updatedData = {
      ...formData,
      age: calculatedAge,
      bmi: bmiValue,
      bmiPercentile,
      bodyFat: bodyFatValue,
      tdee,
      bmr,
      fitnessLevel,
      endDate, // si no hay plan, se reasigna
    };

    // Guardar (métricas + plan)
    await saveFormData(updatedData);

    // Mostrar alerta y scrollear
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
  };

  // Manejar cambios en campos de texto / select
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsSaved(false);
  };

  // Para checkbox (useCreatine)
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: checked,
    }));
    setIsSaved(false);
  };

  // Para slider de actividad
  const handleActivityFactorChange = (event) => {
    const newValue = parseFloat(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      activityFactor: newValue,
    }));
    setIsSaved(false);
  };

  // Manejo de la duración (solo si _planId === null)
  const handleDurationChange = (e) => {
    const days = Number(e.target.value);
    setFormData((prev) => ({
      ...prev,
      planDuration: days,
    }));
    setIsSaved(false);
  };

  // Etiquetas para los valores de actividad
  const activityLevels = [
    { label: 'Sedentario', value: 1.2 },
    { label: 'Ligera actividad', value: 1.375 },
    { label: 'Actividad moderada', value: 1.55 },
    { label: 'Alta actividad', value: 1.725 },
    { label: 'Actividad muy alta', value: 1.9 },
  ];

  // Mostrar/Hundir SVG
  const toggleSVG = (type) => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    setVisibleSVG(type);
    timeoutId.current = setTimeout(() => {
      setVisibleSVG(null);
      const inputField = document.getElementById(type);
      if (inputField) {
        inputField.focus();
      }
    }, 3000);
  };

  useEffect(() => {
    if (visibleSVG && svgContainerRef.current) {
      svgContainerRef.current.focus();
    }
  }, [visibleSVG]);

  useEffect(() => {
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  return {
    formData,
    handleSubmit,
    handleDelete: () => handleDelete(isPlanActive),
    handleInputChange,
    handleCheckboxChange,
    handleActivityFactorChange,
    handleDurationChange,
    errorMessages,
    isSaved,
    isPlanActive,
    showAlert,
    activityLevels,
    generateSummary,
    resultsRef,
    visibleSVG,
    toggleSVG,
    svgContainerRef,
    error, // Añadido para manejar errores
  };
};

export default useHealthMetrics;
