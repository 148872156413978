// healthCalculations.js

/**
 * Calcula el estado físico (fitnessLevel) a partir de sex, age, bmi y bodyFat.
 * Ajusta las condiciones según tu criterio o mantén la lógica actual.
 */
export const calculateFitnessLevel = (sex, age, bmi, bodyFat) => {
  // Si no tienes BMI o BodyFat calculado, podrías retornar algo por defecto:
  if (!bmi || !bodyFat || !age) {
    return 'Bajo estado físico';
  }

  if (sex === 'male') {
    if (age <= 35) {
      if (bmi >= 25 && bodyFat < 15) {
        return 'Estado físico atlético';
      } else if ((bmi >= 23 && bodyFat < 20) || (bmi >= 25 && bodyFat < 25)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 20 && bodyFat < 25) || (bmi >= 23 && bodyFat < 30)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    } else if (age <= 50) {
      if (bmi >= 23 && bodyFat < 17) {
        return 'Estado físico atlético';
      } else if ((bmi >= 22 && bodyFat < 22) || (bmi >= 23 && bodyFat < 27)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 20 && bodyFat < 27) || (bmi >= 22 && bodyFat < 32)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    } else {
      if (bmi >= 23 && bodyFat < 20) {
        return 'Estado físico atlético';
      } else if ((bmi >= 22 && bodyFat < 25) || (bmi >= 23 && bodyFat < 30)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 20 && bodyFat < 30) || (bmi >= 22 && bodyFat < 35)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    }
  } else {
    // female
    if (age <= 35) {
      if (bmi >= 23 && bodyFat < 18) {
        return 'Estado físico atlético';
      } else if ((bmi >= 21 && bodyFat < 24) || (bmi >= 23 && bodyFat < 28)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 19 && bodyFat < 28) || (bmi >= 21 && bodyFat < 35)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    } else if (age <= 50) {
      if (bmi >= 22 && bodyFat < 20) {
        return 'Estado físico atlético';
      } else if ((bmi >= 21 && bodyFat < 26) || (bmi >= 22 && bodyFat < 30)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 19 && bodyFat < 30) || (bmi >= 21 && bodyFat < 37)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    } else {
      if (bmi >= 22 && bodyFat < 22) {
        return 'Estado físico atlético';
      } else if ((bmi >= 21 && bodyFat < 28) || (bmi >= 22 && bodyFat < 35)) {
        return 'Estado físico bueno';
      } else if ((bmi >= 19 && bodyFat < 35) || (bmi >= 21 && bodyFat < 40)) {
        return 'Estado físico normal';
      } else {
        return 'Bajo estado físico';
      }
    }
  }
};

/**
 * Calcula la edad a partir de la fecha de nacimiento.
 */
export const calculateAge = (dob) => {
  if (!dob) return null;
  const birthDate = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

/**
 * Calcula el IMC a partir de peso (kg) y estatura (cm).
 */
export const calculateBMI = (weight, height) => {
  if (weight && height) {
    const heightInMeters = height / 100;
    return (weight / (heightInMeters * heightInMeters)).toFixed(2);
  }
  return null;
};

/**
 * Calcula (aproximadamente) el percentil de IMC si es menor de 18 años.
 */
export const calculateBMIPercentile = (age, sex, bmi) => {
  if (!age || age >= 18 || !bmi) return null;
  // Lógica de ejemplo para percentil
  const percentile = Math.min(Math.max((bmi - 14) * 5, 1), 99).toFixed(1);
  return percentile;
};

/**
 * Calcula el % de grasa corporal usando la fórmula de la Marina de EE.UU.
 * Para hombres, se necesita cuello y cintura.
 * Para mujeres, se necesita cuello, cintura y cadera.
 */
export const calculateBodyFat = (sex, weight, height, age, neck, waist, hip) => {
  if (!age) return null;

  // Validaciones mínimas
  if (sex === 'male') {
    if (!neck || !waist) return null;
    if (waist - neck <= 0) return null;
  } else if (sex === 'female') {
    if (!neck || !waist || !hip) return null;
    if (waist + hip - neck <= 0) return null;
  } else {
    return null;
  }

  let result;

  weight = parseFloat(weight);
  height = parseFloat(height);
  neck = parseFloat(neck);
  waist = parseFloat(waist);
  hip = parseFloat(hip);

  if (sex === 'male') {
    result =
      86.01 * Math.log10(waist - neck) -
      70.041 * Math.log10(height) +
      36.76;
  } else {
    result =
      163.205 * Math.log10(waist + hip - neck) -
      97.684 * Math.log10(height) -
      78.387;
  }

  return result.toFixed(2);
};

/**
 * Calcula BMR y TDEE según Mifflin-St Jeor (para adultos),
 * y fórmulas de OMS para niños/adolescentes si < 18.
 */
export const calculateTDEE = (sex, weight, height, age, activityFactor) => {
  if (!age) return { bmr: null, tdee: null };

  let bmr;
  weight = parseFloat(weight);
  height = parseFloat(height);

  if (age >= 18) {
    // Adultos
    if (sex === 'male') {
      bmr = 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
    } else {
      bmr = 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
    }
  } else {
    // Niños/Adolescentes (ejemplo simplificado)
    if (sex === 'male') {
      if (age <= 3) {
        bmr = 59.512 * weight - 30.4;
      } else if (age <= 10) {
        bmr = 22.706 * weight + 504.3;
      } else {
        bmr = 17.686 * weight + 658.2;
      }
    } else {
      if (age <= 3) {
        bmr = 58.317 * weight - 31.1;
      } else if (age <= 10) {
        bmr = 20.315 * weight + 485.9;
      } else {
        bmr = 13.384 * weight + 692.6;
      }
    }
  }

  const tdee = (bmr * activityFactor).toFixed(2);
  return { bmr: bmr.toFixed(2), tdee };
};

/**
 * Genera un texto de resumen basado en las métricas calculadas.
 * Puedes personalizarlo según tu gusto/criterio.
 */
export const generateSummary = (data) => {
  let summary = '';

  const age = data.age;
  const bmi = parseFloat(data.bmi);
  const weight = parseFloat(data.weight);
  const height = parseFloat(data.height) / 100;

  // Si es adulto, análisis por IMC clásico
  if (age >= 18) {
    if (bmi < 16.0) {
      const targetWeight = 18.5 * height * height;
      const weightToGain = (targetWeight - weight).toFixed(1);
      summary += `Tu IMC indica <span class="text-red-500 font-bold">desnutrición severa</span>. 
                  Necesitas ganar aproximadamente ${weightToGain} kg para alcanzar un IMC de 18.5. `;
    } else if (bmi >= 16.0 && bmi <= 16.9) {
      const targetWeight = 18.5 * height * height;
      const weightToGain = (targetWeight - weight).toFixed(1);
      summary += `Tu IMC indica <span class="text-red-500 font-bold">desnutrición moderada</span>. 
                  Necesitas ganar aproximadamente ${weightToGain} kg para alcanzar un IMC de 18.5. `;
    } else if (bmi >= 17.0 && bmi <= 18.4) {
      const targetWeight = 18.5 * height * height;
      const weightToGain = (targetWeight - weight).toFixed(1);
      summary += `Tu IMC indica <span class="text-red-500 font-bold">desnutrición leve</span>. 
                  Necesitas ganar aproximadamente ${weightToGain} kg para alcanzar un IMC de 18.5. `;
    } else if (bmi >= 18.5 && bmi <= 24.9) {
      summary += 'Tu IMC está en el <span class="text-green-500 font-bold">rango normal</span>. ¡Continúa con tus hábitos saludables! ';
    } else if (bmi >= 25.0 && bmi <= 29.9) {
      const targetWeight = 24.9 * height * height;
      const weightToLose = (weight - targetWeight).toFixed(1);
      summary += `Tu IMC indica <span class="text-red-500 font-bold">sobrepeso</span>. 
                  Necesitas perder aproximadamente ${weightToLose} kg para alcanzar un IMC de 24.9. `;
    } else if (bmi >= 30.0 && bmi <= 34.9) {
      const targetWeight = 24.9 * height * height;
      const weightToLose = (weight - targetWeight).toFixed(1);
      summary += `Tu IMC indica <span class="text-red-500 font-bold">obesidad grado I</span>. 
                  Necesitas perder aproximadamente ${weightToLose} kg para alcanzar un IMC de 24.9. `;
    } else if (bmi >= 35.0 && bmi <= 39.9) {
      const targetWeight = 24.9 * height * height;
      const weightToLose = (weight - targetWeight).toFixed(1);
      summary += `Tu IMC indica <span class="text-red-500 font-bold">obesidad grado II</span>. 
                  Necesitas perder aproximadamente ${weightToLose} kg para alcanzar un IMC de 24.9. `;
    } else if (bmi >= 40.0) {
      const targetWeight = 24.9 * height * height;
      const weightToLose = (weight - targetWeight).toFixed(1);
      summary += `Tu IMC indica <span class="text-red-500 font-bold">obesidad grado III (mórbida)</span>. 
                  Necesitas perder aproximadamente ${weightToLose} kg para alcanzar un IMC de 24.9. `;
    }
  } 
  // Si es menor de edad, análisis con percentil aproximado
  else {
    const bmiPercentile = parseFloat(data.bmiPercentile);
    if (!bmiPercentile) {
      summary += 'No se pudo calcular el percentil de IMC (edad o datos incompletos). ';
    } else {
      if (bmiPercentile < 5) {
        summary += `Tu IMC está por debajo del percentil 5 (${bmiPercentile}), indicando <span class="text-red-500 font-bold">bajo peso</span>. `;
      } else if (bmiPercentile >= 5 && bmiPercentile < 85) {
        summary += `Tu IMC está en un <span class="text-green-500 font-bold">rango saludable</span> (${bmiPercentile} percentil). `;
      } else if (bmiPercentile >= 85 && bmiPercentile < 95) {
        summary += `Tu IMC está en el percentil ${bmiPercentile}, indicando <span class="text-red-500 font-bold">sobrepeso</span>. `;
      } else {
        summary += `Tu IMC está por encima del percentil 95 (${bmiPercentile}), indicando <span class="text-red-500 font-bold">obesidad</span>. `;
      }
    }
  }

  // Análisis adicional con % de grasa corporal
  if (data.bodyFat) {
    const bodyFat = parseFloat(data.bodyFat);
    let idealBodyFatRange = { min: 0, max: 0 };

    if (age >= 18) {
      if (data.sex === 'male') {
        idealBodyFatRange = { min: 8, max: 19 };
      } else {
        idealBodyFatRange = { min: 21, max: 33 };
      }
    } else {
      // Niños/Adolescentes (valores de ejemplo)
      if (data.sex === 'male') {
        idealBodyFatRange = { min: 12, max: 20 };
      } else {
        idealBodyFatRange = { min: 15, max: 24 };
      }
    }

    if (bodyFat < idealBodyFatRange.min) {
      const percentToGain = (idealBodyFatRange.min - bodyFat).toFixed(1);
      summary += `Tu % de grasa corporal es <span class="text-red-500 font-bold">bajo</span>. 
                  Deberías aumentar ~${percentToGain}% para estar en un rango saludable. `;
    } else if (bodyFat > idealBodyFatRange.max) {
      const percentToLose = (bodyFat - idealBodyFatRange.max).toFixed(1);
      summary += `Tu % de grasa corporal es <span class="text-red-500 font-bold">alto</span>. 
                  Deberías reducir ~${percentToLose}% para estar en un rango saludable. `;
    } else {
      summary += 'Tu % de grasa corporal está dentro del <span class="text-green-500 font-bold">rango ideal</span>. ¡Excelente! ';
    }
  }

  // Recomendación dietaria simple según TDEE
  if (data.tdee) {
    const tdee = parseFloat(data.tdee);

    if (bmi < 18.5 || (age < 18 && data.bmiPercentile < 5)) {
      const surplusCalories = 500;
      summary += `Para ganar peso, podrías aumentar tu ingesta ~${surplusCalories} calorías por día, 
                  comiendo unas ${(tdee + surplusCalories).toFixed(0)} calorías diarias. `;
    } else if (bmi >= 25 || (age < 18 && data.bmiPercentile >= 85)) {
      const deficitCalories = 500;
      summary += `Para perder peso, podrías reducir tu ingesta ~${deficitCalories} calorías por día, 
                  comiendo unas ${(tdee - deficitCalories).toFixed(0)} calorías diarias. `;
    } else {
      summary += `Para mantener tu peso, consume ~${tdee.toFixed(0)} calorías diarias. `;
    }
  }

  // Nota sobre actividad física si es muy baja
  if (data.activityFactor) {
    if (data.activityFactor <= 1.375) {
      summary += 'Considera incrementar tu nivel de actividad física para mejorar tu salud. ';
    }
  }

  return summary || 'Completa tus métricas para obtener un resumen personalizado.';
};
